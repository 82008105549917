import React, { useState, useEffect } from 'react';
import AntdButton from 'component/elements/AntdButton';
import { getToken, getUserData } from 'helper';
import { useLocation } from 'react-router-dom';
import LoadingSpinner from 'component/Loader';

const winWidth = window.innerWidth;

const WaitingRoom = () => {
  const [user, setUser] = useState<any>(JSON.parse(getUserData()) || null);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  const [height, setHeight] = useState<any>(220);
  const [trainer, setTrainer] = useState<any>('');

  useEffect(() => {
    const token = getToken();
    if (!token) {
      setLoading(false);
      setError('No token');
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    let queryParams = new URLSearchParams(location.search);
    let trainerQuery = queryParams.get('trainer');
    // console.log('abc', trainerQuery);
    setTrainer(trainerQuery);
    return () => {};
  }, [location]);

  const onSubmit = () => {};
  const onLoad = e => {
    setHeight((winWidth * 220) / 360);
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <span style={{ padding: 15 }}>{error}</span>;
  return (
    <div>
      <div style={{ paddingTop: 15, marginBottom: 15, textAlign: 'center' }}>
        <h3 style={{ fontSize: '2rem' }}>{trainer} Virtual Waiting Room</h3>
        <p style={{ fontSize: '1.25rem' }}>Please wait for your trainer to arrive.</p>
      </div>
      <div style={{ marginBottom: 10 }}>
        <iframe title="game" id="Iframe" src="https://cdn.htmlgames.com/SimonSays/" style={{ width: '100%', height: height }} onLoad={onLoad} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 15, padding: '0px 15px' }}>
        <AntdButton size="large" type="primary" style={{ marginBottom: 15 }} onClick={onSubmit}>
          Trainer Sign in
        </AntdButton>
      </div>
    </div>
  );
};

export default WaitingRoom;
