import React, { useContext, useEffect, useState } from "react";
import { getUserData, setUserData, getToken } from "helper";
import moment from "moment";
import AntdButton from "component/elements/AntdButton";
import { Button, notification } from "antd";
import LoadingSpinner from "component/Loader";
import { ThemeContext } from "styled-components";
import { useHistory } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";

const PurchaseMoreTraining = () => {
  const theme = useContext(ThemeContext);
  const history = useHistory();
  const [user, setUser] = useState<any>(JSON.parse(getUserData()) || null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const token = getToken();
    if (!token) {
      setLoading(false);
      setError("No token");
    } else {
      setLoading(false);
    }
  }, []);

  const handleGet = (type) => {
    switch (type) {
      case "5to9":
        window.location.assign(
          "https://tpasc.ezfacility.com/Cart/ShoppingItemsDirect?type=package&id=146553"
        );
        break;
      case "10to19":
        window.location.assign(
          "https://tpasc.ezfacility.com/Cart/ShoppingItemsDirect?type=package&id=146554"
        );
        break;
      case "20to39":
        window.location.assign(
          "https://tpasc.ezfacility.com/Cart/ShoppingItemsDirect?type=package&id=146555"
        );
        break;
      case "40plus":
        window.location.assign(
          "https://tpasc.ezfacility.com/Cart/ShoppingItemsDirect?type=package&id=146557"
        );
        break;
    }
  };

  if (loading) return <LoadingSpinner />;
  return (
    <div style={{ padding: 0 }}>
      <div style={{ paddingTop: 10 }}>
        <Button type="text" onClick={() => history.goBack()}>
          <LeftOutlined style={{ color: "#000", fontSize: 18 }} />
        </Button>
      </div>
      {error ? (
        <span style={{ padding: 15 }}>{error}</span>
      ) : (
        <div style={{ padding: "5px 15px" }}>
          <div style={{ marginBottom: 15, textAlign: "center" }}>
            <h3 style={{ margin: 0 }}>Purchase More Training</h3>
            <p style={{ fontStyle: "italic" }}>
              You will be required to sign in again.
            </p>
          </div>
          <div
            style={{
              marginBottom: 20,
              textAlign: "center",
              border: "1px solid #e1e1e1",
              padding: 10,
              borderRadius: 10,
            }}
          >
            <h3 style={{ color: "#000", margin: 0 }}>
              10-19 Session - $64 per session
            </h3>{" "}
            <AntdButton
              type="primary"
              style={{ width: "70%", marginTop: 10 }}
              onClick={() => handleGet("10to19")}
            >
              Get
            </AntdButton>
          </div>
          <div
            style={{
              marginBottom: 20,
              textAlign: "center",
              border: "1px solid #e1e1e1",
              padding: 10,
              borderRadius: 10,
            }}
          >
            <h3 style={{ color: "#000", margin: 0 }}>
              20-39 Session - $58 per session
            </h3>{" "}
            <AntdButton
              type="primary"
              style={{ width: "70%", marginTop: 10 }}
              onClick={() => handleGet("20to39")}
            >
              Get
            </AntdButton>
          </div>
          <div
            style={{
              marginBottom: 20,
              textAlign: "center",
              border: "1px solid #e1e1e1",
              padding: 10,
              borderRadius: 10,
            }}
          >
            <h3 style={{ color: "#000", margin: 0 }}>
              40+ Session - $52 per session
            </h3>{" "}
            <AntdButton
              type="primary"
              style={{ width: "70%", marginTop: 10 }}
              onClick={() => handleGet("40plus")}
            >
              Get
            </AntdButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default PurchaseMoreTraining;
