import React, { useRef } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import VideoItem from 'component/VideoItem/VideoItem';
import './styles.css';
import { LeftOutlined, RightOutlined, DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { CardWrapper } from './VideoListScrollStyles';

const Arrow = ({ text }) => {
  return <div>{text == 'left' ? <DoubleLeftOutlined style={{ fontSize: '2em' }} /> : <DoubleRightOutlined style={{ fontSize: '2em' }} />}</div>;
};
const VideoListScroll = ({ videos, onClick }) => {
  const menuRef = useRef(null);

  const onClickItem = (val) => {
    // console.log('item', val);
    // onClick(val);
  };

  const onSelect = (selected) => {
    // console.log('s', selected);
    const videoItem = videos.find((item) => item._id == selected);
    // console.log('item', hello);
    onClick(videoItem);
  };
  // const onUpdate = (ss) => {
  //   console.log('onUpdate: translate:', ss);
  // };
  const menu = videos.map((item) => {
    return (
      <CardWrapper key={item._id}>
        <VideoItem onClick={onClickItem} item={item} />
      </CardWrapper>
    );
  });

  return (
    <ScrollMenu
      alignCenter={false}
      // arrowLeft={<Arrow text="left" />}
      // arrowRight={<Arrow text="right" />}
      clickWhenDrag={false}
      data={menu}
      dragging={true}
      hideArrows={true}
      hideSingleArrow={true}
      // onFirstItemVisible={this.onFirstItemVisible}
      // onLastItemVisible={this.onLastItemVisible}
      onSelect={onSelect}
      // onUpdate={onUpdate}
      ref={menuRef}
      scrollToSelected={false}
      transition={1}
      translate={0}
      itemStyle={{ outline: 'none' }}
      wheel={false}
    />
  );
};

export default VideoListScroll;
