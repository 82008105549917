export const GENERAL_HEALTH_Q = [
  {
    label: 'gq1',
    question: 'Has your doctor ever said that you have a heart condition or high blood pressure?',
    type: 'radio',
    possibleAns: ['YES', 'NO'],
    subQuestion: [
      {
        label: 'gq1a',
        question: 'Heart condition OR High blood pressure?',
        type: 'checkbox',
        possibleAns: ['Heart condition', 'High blood pressure'],
      },
    ],
  },
  {
    label: 'gq2',
    question: 'Do you feel pain in your chest at rest, during your daily activities of living, OR when you do physical activity?',
    type: 'radio',
    possibleAns: ['YES', 'NO'],
    subQuestion: [],
  },
  {
    label: 'gq3',
    question:
      'Do you lose balance because of dizziness OR have you lost consciousness in the last 12 months?(Please answer NO if your dizziness was associated with over-breathing including during vigorous exercise)',
    type: 'radio',
    possibleAns: ['YES', 'NO'],
    subQuestion: [],
  },
  {
    label: 'gq4',
    question: 'Have you ever been diagnosed with another chronice medical condition (other than heart disease or high blood pressure)?',
    type: 'radio',
    possibleAns: ['YES', 'NO'],
    subQuestion: [
      {
        label: 'gq4a',
        question: 'Please list condition(s) here',
        type: 'input',
        // possibleAns: ['Heart condition', 'High blood pressure'],
      },
    ],
  },
  {
    label: 'gq5',
    question: 'Are you currently taking prescribed medications for a chronic medical condition?',
    type: 'radio',
    possibleAns: ['YES', 'NO'],
    subQuestion: [
      {
        label: 'gq5a',
        question: 'Please list condition(s) here',
        type: 'input',
        // possibleAns: ['Heart condition', 'High blood pressure'],
      },
    ],
  },
  {
    label: 'gq6',
    question:
      'Do you currently have (or have had within the past 12 months) a bone, joint, or soft tissue (muscle, ligament, or tendon) problem that could be made worse by becoming more physically active? Please answer NO if you had a problem in the past, but it does not limit your current ability to be physically active.',
    type: 'radio',
    possibleAns: ['YES', 'NO'],
    subQuestion: [
      {
        label: 'gq6a',
        question: 'Please list condition(s) here',
        type: 'input',
        // possibleAns: ['Heart condition', 'High blood pressure'],
      },
    ],
  },
  {
    label: 'gq7',
    question: 'Has your doctor ever said that you should only do medically supervised physical activity?',
    type: 'radio',
    possibleAns: ['YES', 'NO'],
    subQuestion: [],
  },
];

export const FOLLOW_UP_Q = [
  {
    label: 'fq1',
    question: 'Do you have Arthritis, Osteoporosis, or Back Problems?',
    type: 'radio',
    possibleAns: ['YES', 'NO'],
    subQuestion: [
      {
        label: 'fq1a',
        question: `Do you have difficulty controlling your condition with medications or other physician-prescribed therapies?(Answer NO if you are not currently taking medications or other treatments)`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
      {
        label: 'fq1b',
        question: `Do you have joint problems causing pain, a recent fracture or fracture caused by osteoporosis or cancer displaced vertebra (e.g., spondylolisthesis), and/or spondylolysis/pars defect (a crack in the bony ring on the back of the spinal column)?`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
      {
        label: 'fq1c',
        question: `Have you had steroid injections or taken steroid tablets regularly for more than 3 months?`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
    ],
  },
  {
    label: 'fq2',
    question: 'Do you currently have Cancer of any kind?',
    type: 'radio',
    possibleAns: ['YES', 'NO'],
    subQuestion: [
      {
        label: 'fq2a',
        question: `Does your cancer diagnosis include any of the following types: lung/bronchogenic, multiple myeloma (cancer of plasma cells), head, and/or neck?`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
      {
        label: 'fq2b',
        question: `Are you currently receiving cancer therapy (such as chemotheraphy or radiotherapy)?`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
    ],
  },
  {
    label: 'fq3',
    question:
      'Do you have a Heart or Cardiovascular Condition? This includes Coronary Artery Disease, Heart Failure, Diagnosed Abnormality of Heart Rhythm',
    type: 'radio',
    possibleAns: ['YES', 'NO'],
    subQuestion: [
      {
        label: 'fq3a',
        question: `Do you have difficulty controlling your condition with medications or other physician-prescribed therapies? (Answer NO if you are not currently taking medications or other treatments)`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
      {
        label: 'fq3b',
        question: `Do you have an irregular heart beat that requires medical management? (e.g., atrial fibrillation, premature ventricular contraction)`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
      {
        label: 'fq3c',
        question: `Do you have chronic heart failure?`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
      {
        label: 'fq3d',
        question: `Do you have diagnosed coronary artery (cardiovascular) disease and have not participated in regular physical activity in the last 2 months?`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
    ],
  },
  {
    label: 'fq4',
    question: 'Do you currently have High Blood Pressure?',
    type: 'radio',
    possibleAns: ['YES', 'NO'],
    subQuestion: [
      {
        label: 'fq4a',
        question: `Do you have difficulty controlling your condition with medications or other physician-prescribed therapies? (Answer NO if you are not currently taking medications or other treatments)`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
      {
        label: 'fq4b',
        question: `Do you have a resting blood pressure equal to or greater than 160/90 mmHg with or without medication? (Answer YES if you do not know your resting blood pressure)`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
    ],
  },
  {
    label: 'fq5',
    question: 'Do you have any Metabolic Conditions? This includes Type 1 Diabetes, Type 2 Diabetes, Pre-Diabetes',
    type: 'radio',
    possibleAns: ['YES', 'NO'],
    subQuestion: [
      {
        label: 'fq5a',
        question: `Do you often have difficulty controlling your blood sugar levels with foods, medications, or other physician-prescribed therapies?`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
      {
        label: 'fq5b',
        question: `Do you often suffer from signs and symptoms of low blood sugar (hypoglycemia) following exercise and/or during activities of daily living? Signs of hypoglycemia may include shakiness, nervousness, unusual irritability, abnormal sweating, dizziness or light-headedness, mental confusion, difficulty speaking, weakness, or sleepiness.`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
      {
        label: 'fq5c',
        question: `Do you have any signs or symptoms of diabetes complications such as heart or vascular disease and/or complications affecting your eyes, kidneys, OR the sensation in your toes and feet?`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
      {
        label: 'fq5d',
        question: `Do you have other metabolic conditions (such as current pregnancy-related diabetes, chronic kidney disease, or liver problems)?`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
      {
        label: 'fq5e',
        question: `Are you planning to engage in what for you is unusually high (or vigorous) intensity exercise in the near future?`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
    ],
  },
  {
    label: 'fq6',
    question: `Do you have any Mental Health Problems or Learning Difficulties? This includes Alzheimer’s, Dementia, Depression, Anxiety Disorder, Eating Disorder, Psychotic Disorder, Intellectual Disability, Down Syndrome`,
    type: 'radio',
    possibleAns: ['YES', 'NO'],
    subQuestion: [
      {
        label: 'fq6a',
        question: `Do you have difficulty controlling your condition with medications or other physician-prescribed therapies? (Answer NO if you are not currently taking medications or other treatments)`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
      {
        label: 'fq6b',
        question: `Do you have Down Syndrome AND back problems aecting nerves or muscles?`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
    ],
  },
  {
    label: 'fq7',
    question: `Do you have a Respiratory Disease? This includes Chronic Obstructive Pulmonary Disease, Asthma, Pulmonary High Blood Pressure`,
    type: 'radio',
    possibleAns: ['YES', 'NO'],
    subQuestion: [
      {
        label: 'fq7a',
        question: `Do you have dfficulty controlling your condition with medications or other physician-prescribed therapies? (Answer NO if you are not currently taking medications or other treatments)`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
      {
        label: 'fq7b',
        question: `Has your doctor ever said your blood oxygen level is low at rest or during exercise and/or that you require supplemental oxygen therapy?`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
      {
        label: 'fq7c',
        question: `If asthmatic, do you currently have symptoms of chest tightness, wheezing, laboured breathing, consistent cough (more than 2 days/week), or have you used your rescue medication more than twice in the last week?`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
      {
        label: 'fq7d',
        question: `Has your doctor ever said you have high blood pressure in the blood vessels of your lungs?`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
    ],
  },
  {
    label: 'fq8',
    question: 'Do you have a Spinal Cord Injury? This includes Tetraplegia and Paraplegia',
    type: 'radio',
    possibleAns: ['YES', 'NO'],
    subQuestion: [
      {
        label: 'fq8a',
        question: `Do you have difficulty controlling your condition with medications or other physician-prescribed therapies? (Answer NO if you are not currently taking medications or other treatments)`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
      {
        label: 'fq8b',
        question: `Do you commonly exhibit low resting blood pressure signicant enough to cause dizziness, light-headedness, and/or fainting?`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
      {
        label: 'fq8c',
        question: `Has your physician indicated that you exhibit sudden bouts of high blood pressure (known as Autonomic Dysreflexia)?`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
    ],
  },
  {
    label: 'fq9',
    question: 'Have you had a Stroke? This includes Transient Ischemic Attack (TIA) or Cerebrovascular Event',
    type: 'radio',
    possibleAns: ['YES', 'NO'],
    subQuestion: [
      {
        label: 'fq9a',
        question: `Do you have difficulty controlling your condition with medications or other physician-prescribed therapies? (Answer NO if you are not currently taking medications or other treatments)`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
      {
        label: 'fq9b',
        question: `Do you have any impairment in walking or mobility?`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
      {
        label: 'fq9c',
        question: `Have you experienced a stroke or impairment in nerves or muscles in the past 6 months?`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
    ],
  },
  {
    label: 'fq10',
    question: 'Do you have any other medical condition not listed above or do you have two or more medical conditions? ',
    type: 'radio',
    possibleAns: ['YES', 'NO'],
    subQuestion: [
      {
        label: 'fq10a',
        question: `Have you experienced a blackout, fainted, or lost consciousness as a result of a head injury within the last 12 months OR have you had a diagnosed concussion within the last 12 months?`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
      {
        label: 'fq10b',
        question: `Do you have a medical condition that is not listed (such as epilepsy, neurological conditions, kidney problems)?`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
      {
        label: 'fq10c',
        question: `Do you currently live with two or more medical conditions?`,
        type: 'radio',
        possibleAns: ['YES', 'NO'],
      },
      {
        label: 'fq10d',
        question: `Please list your medical condition(s) and any related medications here`,
        type: 'input',
      },
    ],
  },
];
