import React, { useState } from 'react';
import { Modal, Select, DatePicker, Button, Col, Radio, Input } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { TRAINERS_QUERY } from 'apollo/trainer/queries';
import { ACTIVITIES_QUERY } from 'apollo/activity/queries';
import LoadingSpinner from 'component/Loader';
import Loader from 'react-loader-spinner';
import { LabelInputWrapper, LabelWrapper, InputWrapper, MainWrapper } from './VideoFilterModalStyles';

const { Option } = Select;

const equipmentsList = [
  { label: 'Body Weight', key: 'bodyWeight' },
  { label: 'Dumbbells', key: 'dumbbells' },
];

const VideoFilterModal = ({ visible, onDone, onCancel }) => {
  const [trainers, setTrainers] = useState<null | Array<Object>>(null);
  const [activities, setActivities] = useState<null | Array<Object>>(null);
  const [trainer, setTrainer] = useState('all');
  const [activity, setActivity] = useState('all');
  const [intensity, setIntensity] = useState('all');
  const [equipments, setEquipments] = useState([]);
  const [duration, setDuration] = useState(0);
  const [date, setDate] = useState(null);

  useQuery(TRAINERS_QUERY, {
    onCompleted: (data) => {
      setTrainers(data.trainers);
    },
    onError: (err) => {
      console.log('trainer err', err);
    },
  });
  useQuery(ACTIVITIES_QUERY, {
    onCompleted: (data) => {
      setActivities(data.activities);
    },
    onError: (err) => {
      console.log('activity err', err);
    },
  });
  const renderEquipments = equipmentsList.map((item) => (
    <Option key={item.key} value={item.key}>
      {item.label}
    </Option>
  ));
  const handleActivityChange = (value) => {
    // console.log('activity', value);
    setActivity(value);
  };
  const handleTrainerChange = (value) => {
    // console.log('trainer', value);
    setTrainer(value);
  };
  const handleIntensityChange = (value) => {
    // console.log('intensity', value.target.value);
    setIntensity(value.target.value);
  };
  const handleEquipmentChange = (value) => {
    // console.log('equipment', value);
    setEquipments(value);
  };
  const handleDateChange = (value) => {
    // console.log('date', value);
    setDate(value);
  };
  const handleDurationChange = (value) => {
    // console.log('date', value);
    setDuration(value);
  };

  const onClearFilter = () => {
    setActivity('all');
    setTrainer('all');
    setIntensity('all');
    setEquipments([]);
    setDuration(0);
    setDate(null);
  };

  const handleOk = () => {
    let activityId: null | Array<any> = null;
    let trainerId: null | Array<any> = null;
    if (activity !== 'all') {
      activityId = activities && activities.filter((item: any) => item.name == activity);
    }
    if (trainer !== 'all') {
      trainerId = trainers && trainers.filter((item: any) => item.name == trainer);
    }
    onDone({
      activity: activity,
      trainer: trainer,
      date: date,
      ...(activityId && { activityId: activityId[0]._id }),
      ...(trainerId && { trainerId: trainerId[0]._id }),
      intensity: intensity,
      equipments: equipments,
      duration: duration,
    });
  };

  return (
    <Modal
      title="Filter Videos"
      visible={visible}
      onOk={onDone}
      onCancel={onCancel}
      footer={[
        <Button key="clear" onClick={onClearFilter}>
          Clear Filter
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          Ok
        </Button>,
      ]}
    >
      {trainers && activities ? (
        <MainWrapper>
          <LabelInputWrapper>
            <LabelWrapper>
              <span>Activity:</span>
            </LabelWrapper>
            <InputWrapper>
              <Select
                value={activity}
                style={{ width: '100%' }}
                onChange={handleActivityChange}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option value="all">All</Option>
                {activities.map((item: any) => {
                  return (
                    <Option key={item._id} value={item.name}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </InputWrapper>
          </LabelInputWrapper>
          <LabelInputWrapper style={{ marginTop: 15 }}>
            <LabelWrapper>
              <span>Trainer:</span>
            </LabelWrapper>
            <InputWrapper>
              <Select
                showSearch
                value={trainer}
                style={{ width: '100%' }}
                onChange={handleTrainerChange}
                optionFilterProp="children"
                filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option value="all">All</Option>
                {trainers.map((item: any) => {
                  return (
                    <Option key={item._id} value={item.name}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </InputWrapper>
          </LabelInputWrapper>
          <LabelInputWrapper style={{ marginTop: 15 }}>
            <LabelWrapper>
              <span>Intensity:</span>
            </LabelWrapper>
            <InputWrapper>
              <Radio.Group defaultValue="all" onChange={handleIntensityChange}>
                <Radio.Button value="all">All</Radio.Button>
                <Radio.Button value="low">Low</Radio.Button>
                <Radio.Button value="medium">Medium</Radio.Button>
                <Radio.Button value="high">High</Radio.Button>
              </Radio.Group>
            </InputWrapper>
          </LabelInputWrapper>
          <LabelInputWrapper style={{ marginTop: 15 }}>
            <LabelWrapper>
              <span>Equipments:</span>
            </LabelWrapper>
            <InputWrapper>
              <Select mode="tags" style={{ width: '100%' }} placeholder="Select Equipment" onChange={handleEquipmentChange}>
                {renderEquipments}
              </Select>
            </InputWrapper>
          </LabelInputWrapper>
          <LabelInputWrapper style={{ marginTop: 15 }}>
            <LabelWrapper>
              <span>Duration:</span>
            </LabelWrapper>
            <InputWrapper>
              <Select value={duration} style={{ width: '100%' }} onChange={handleDurationChange}>
                <Option value={0}>All</Option>
                <Option value={1800}>Less than 30 mins</Option>
                <Option value={2700}>Less than 45 mins</Option>
                <Option value={3600}>Greater than 60 mins</Option>
              </Select>
            </InputWrapper>
          </LabelInputWrapper>
          <LabelInputWrapper style={{ marginTop: 15 }}>
            <LabelWrapper>
              <span>Date:</span>
            </LabelWrapper>
            <InputWrapper>
              <DatePicker value={date} style={{ width: '100%' }} onChange={handleDateChange} />
            </InputWrapper>
          </LabelInputWrapper>
        </MainWrapper>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Loader type="TailSpin" color="#202541" />
        </div>
      )}
    </Modal>
  );
};

export default VideoFilterModal;
