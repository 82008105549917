import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { setToken, removeToken, removeUserData, getUserData, getToken } from '../../../helper';
import { Form, Card, Input, Button } from 'antd';
import { LockOutlined, UserOutlined, RightOutlined } from '@ant-design/icons';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { EZ_CLIENT_LOGGN } from 'apollo/user/mutations';
import { Link, useHistory } from 'react-router-dom';
import { USER_FROM_TOKEN_QUERY } from 'apollo/user/queries';

const LoginWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
`;

const CardWrapper = styled.div`
  width: 100%;
`;

const CardTitle = styled.div`
  font-size: ${(props: any) => props.theme.fontSizes.large};
  font-weight: 700;
  text-align: center;
  color: ${(props: any) => props.theme.colors.brandDarkGrey};
`;
const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em;
  margin: 1em 0;
  cursor: pointer;
  border: 2px solid ${(props: any) => props.theme.colors.coolGrey};
  border-radius: 0.5rem;
  span {
    margin: 0;
  }
  &:hover {
    color: ${(props: any) => props.theme.colors.brandDarkBlue};
    border: 2px solid ${(props: any) => props.theme.colors.brandDarkGrey};
  }
`;
const LoginText = styled.p`
  font-size: ${(props: any) => props.theme.fontSizes.small};
  color: ${(props: any) => props.theme.colors.brandDarkBlue};
  font-weight: 300;
  text-align: left;
  margin: 0;
  cursor: pointer;
  &:hover {
    color: ${(props: any) => props.theme.colors.brandLightBlue};
  }
`;
interface Props {}

const WantAccess = (props: Props) => {
  const [user, setUser] = useState(JSON.parse(getUserData()) || null);
  const [userFromToken] = useLazyQuery(USER_FROM_TOKEN_QUERY, {
    onCompleted: data => {
      let obj = {
        ClientID: data.userFromToken.clientId,
        firstName: data.userFromToken.firstName,
        lastName: data.userFromToken.lastName,
        MemberPhotoUrl: data.userFromToken.memberPhotoUrl,
      };
      setUser(obj);
    },
  });
  const onClick = type => {
    let url = '';
    if (type == '1DayPass') url = 'https://tpasc.ezfacility.com/Cart/ShoppingItemsDirect?type=package&id=221185';
    if (type == 'videoMembership') url = 'https://tpasc.ezfacility.com/Cart/ShoppingItemsDirect?type=package&id=220318';
    window.location.assign(url);
  };

  useEffect(() => {
    if (getToken() && !JSON.parse(getUserData())) {
      userFromToken();
    }
    return () => {};
  }, []);

  const goBackLogin = async () => {
    await removeToken();
    await removeUserData();
    window.location.href = `${window.location.origin}/auth/login`;
  };

  return (
    <LoginWrapper>
      <div style={{ width: "80%", maxWidth: "300px", marginBottom: "4em" }}>
        <img
          src={require("assets/logos/PanAm_logo_RGB.png")}
          style={{ width: "100%" }}
        />
      </div>
      {/* <h1 style={{ textAlign: "center" }}>Self Service Login</h1> */}
      <CardWrapper>
        <Card
          title={
            <CardTitle>
              Welcome
              {user && !!user.username
                ? ` ${user.username}`
                : user && !!user.firstName && !!user.lastName
                ? ` ${
                    user.firstName[0].toUpperCase() + user.firstName.slice(1)
                  } ${user.lastName[0].toUpperCase() + user.lastName.slice(1)}`
                : ""}
            </CardTitle>
          }
          style={{
            maxWidth: "400px",
            minWidth: "320px",
            width: "90%",
            borderRadius: "5px",
            backgroundColor: "#fff",
            margin: "0 auto",
            boxShadow: "5px 5px 10px 0px rgba(0,0,0,0.3)",
          }}
        >
          <h2 style={{ textAlign: "center", fontSize: "1.15rem" }}>
            Want Access?
          </h2>
          <ButtonsWrapper>
            {/* <StyledButton>
              <span style={{ fontSize: '1.125rem' }}>1 Week Trial</span>
              <RightOutlined style={{ fontSize: '1.5rem', margin: 0 }} />
            </StyledButton> */}
            <StyledButton onClick={() => onClick("1DayPass")}>
              <span style={{ fontSize: "1.125rem" }}>1 Day Pass</span>
              <RightOutlined style={{ fontSize: "1.5rem", margin: 0 }} />
            </StyledButton>
            <StyledButton onClick={() => onClick("videoMembership")}>
              <span style={{ fontSize: "1.125rem" }}>Video Membership</span>
              <RightOutlined style={{ fontSize: "1.5rem", margin: 0 }} />
            </StyledButton>
          </ButtonsWrapper>
          <div style={{ textAlign: "left" }}>
            <LoginText onClick={goBackLogin}>Go to Login Page?</LoginText>
          </div>
        </Card>
      </CardWrapper>
    </LoginWrapper>
  );
};

export default WantAccess;
