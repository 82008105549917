import { gql } from 'apollo-boost';

export const NEXT_ONLINE_CLASS_QUERY = gql`
  query nextOnlineClass {
    nextOnlineClass {
      title
      description
      startTime
      selfServiceSessionUrl
      resourceName
      instructor
    }
  }
`;
