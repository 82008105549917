import React from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import ProtectedRoutes from './ProtectedRoutes';
import PrivateRoutes from './PrivateRoutes';
import Login from '../view/Auth';
import VGSignup from '../view/Auth/Login/VGSignup';
import Error404 from './Error404';
import { getToken } from '../helper';
import WantAccess from 'view/Main/WantAccess';
import MyEzSchedule from 'view/Main/MyEzSchedule';
import EzSessions from 'view/Main/EzSessions';
import SignatureLogin from 'view/Main/SignatureLogin/SingatureLogin';
import VirtualSession from 'view/Main/VirtualSession';
import PersonalTraining from 'view/Main/PersonalTraining';
import PersonalCoaching from 'view/Main/PersonalCoaching';
import PurchaseMoreTraining from 'view/Main/PurchaseMoreTraining';
import GoPro from 'view/Main/GoPro';
import BiweeklyCheckin from 'view/Main/BiweeklyCheckin';
import Parq from 'view/Main/Parq';
import Barcode from 'view/Main/Barcode';
import VideoSession from 'view/Main/VideoSession';
import WaitingRoom from 'view/Main/WaitingRoom';
import CheckSelfService from 'view/Main/CheckSelfService';
import CaptiveLoginForm from 'view/Auth/Login/CaptiveLogin';
import Migrated from 'view/Main/WantAccess/Migrated';

interface Props {}

const RouterPath: React.FC<Props> = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login">
          {getToken() ? <Redirect to="/" /> : <Login />}
        </Route>
        <Route path="/captiveauth">
          <CaptiveLoginForm />
        </Route>
        <Route path="/vgsignup" component={Migrated} />
        <Route path="/checkss" component={Migrated} />
        <Route path="/wantaccess" component={WantAccess} />
        <Route path="/signaturelogin" component={SignatureLogin} />
        <Route path="/myschedule" component={MyEzSchedule} />
        <Route path="/ezsessions" component={EzSessions} />
        <Route path="/virtualsession" component={Migrated} />
        <Route path="/personaltraining" component={Migrated} />
        <Route path="/personalcoaching" component={Migrated} />
        <Route path="/purchasemoretraining" component={PurchaseMoreTraining} />
        <Route path="/gopro" component={Migrated} />
        <Route path="/checkin" component={BiweeklyCheckin} />
        <Route path="/parq" component={Parq} />
        <Route path="/barcode" component={Barcode} />
        <Route path="/videosession" component={VideoSession} />
        <Route path="/waitingroom" component={WaitingRoom} />
        <PrivateRoutes path="/" component={ProtectedRoutes} />
        <Route component={Error404} />
      </Switch>
    </BrowserRouter>
  );
};

export default RouterPath;
