import React, { createElement, useState, useEffect, useContext } from 'react';
import { Comment, Avatar, Tooltip, Dropdown, Menu, Modal } from 'antd';
import {
  DislikeOutlined,
  LikeOutlined,
  DislikeFilled,
  LikeFilled,
  UserOutlined,
  CaretUpFilled,
  CaretDownFilled,
  MoreOutlined,
  EditFilled,
  DeleteFilled,
} from '@ant-design/icons';
import moment from 'moment';
import { LIKE_COMMENT_MUTATION, DISLIKE_COMMENT_MUTATION, DELETE_COMMENT_MUTATION } from 'apollo/comment/mutations';
import { useMutation } from '@apollo/react-hooks';
import { getUserData } from 'helper';
import CommentBox from 'component/CommentBox/CommentBox';
import CommentList from 'component/CommentList/CommentList';
import viewFormatter from 'helper/viewsFormatter';
import { ThemeContext } from 'styled-components';
import { GAEvent } from 'helper/reactga';

const CommentComp = ({ commentItem, canReply, commentIndex, onDelete, onEdit, videoDetails }) => {
  const user = JSON.parse(getUserData());
  const [isLiked, setIsLiked] = useState(commentItem.likes.includes(user.ClientID.toString()));
  const [totalLikes, setTotalLikes] = useState(commentItem.totalLikes || 0);
  const [isDisliked, setIsDisliked] = useState(commentItem.dislikes.includes(user.ClientID.toString()));
  const [totalDislikes, setTotalDislikes] = useState(commentItem.totaldisLikes || 0);
  const [comments, setComments] = useState(commentItem.children || []);
  const [likeComment] = useMutation(LIKE_COMMENT_MUTATION);
  const [dislikeComment] = useMutation(DISLIKE_COMMENT_MUTATION);
  const [deleteComment] = useMutation(DELETE_COMMENT_MUTATION);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const themeContext = useContext(ThemeContext);

  const onCommentAdd = newComment => {
    setComments((prev: any) => [...prev, newComment]);
    setShowCommentBox(false);
  };
  const onCommentEdit = editedComment => {
    setShowCommentBox(false);
    setIsEditing(false);
    onEdit(editedComment, commentIndex);
  };

  const handleDelete = () => {
    GAEvent('Videos', `Delete Comment Video`, videoDetails.title, null, {
      dimension6: videoDetails.trainer.name,
      dimension5: videoDetails.title,
      dimension7: videoDetails.activity.name,
      dimension8: videoDetails.premiereAt ? videoDetails.premiereAt : videoDetails.createdAt,
      metric12: 1,
    });
    deleteComment({
      variables: {
        _id: commentItem._id,
      },
    });
    setComments([]);
    onDelete(commentIndex);
  };

  const showDeleteModal = () => {
    Modal.confirm({
      title: 'Delete Comment',
      content: 'Delete your comment permanently?',
      okText: 'Delete',
      onOk: handleDelete,
      cancelText: 'Cancel',
    });
  };

  const onLike = () => {
    if (!isLiked) {
      if (isDisliked) {
        setIsDisliked(false);
        setTotalDislikes(prev => prev - 1);
      }
      setTotalLikes(prev => prev + 1);
    } else {
      setTotalLikes(prev => prev - 1);
    }
    likeComment({
      variables: {
        input: {
          _id: commentItem._id,
          type: !isLiked,
        },
      },
    });
    setIsLiked(prev => !prev);
  };
  const onDislike = () => {
    if (!isDisliked) {
      if (isLiked) {
        setIsLiked(false);
        setTotalLikes(prev => prev - 1);
      }
      setTotalDislikes(prev => prev + 1);
    } else {
      setTotalDislikes(prev => prev - 1);
    }
    dislikeComment({
      variables: {
        input: {
          _id: commentItem._id,
          type: !isDisliked,
        },
      },
    });
    setIsDisliked(prev => !prev);
  };

  const actions = [
    <Tooltip key="comment-basic-like" title="Like">
      <span
        onClick={onLike}
        style={{ fontSize: '1rem', cursor: 'pointer', color: isLiked ? themeContext.colors.brandLightBlue : themeContext.colors.coolGrey }}
      >
        {createElement(isLiked ? LikeFilled : LikeOutlined)}
        <span className="comment-action">{viewFormatter(totalLikes, 1)}</span>
      </span>
    </Tooltip>,
    <Tooltip key="comment-basic-dislike" title="Dislike">
      <span
        onClick={onDislike}
        style={{ fontSize: '1rem', cursor: 'pointer', color: isDisliked ? themeContext.colors.brandLightBlue : themeContext.colors.coolGrey }}
      >
        {createElement(isDisliked ? DislikeFilled : DislikeOutlined)}
        <span className="comment-action">{viewFormatter(totalDislikes, 1)}</span>
      </span>
    </Tooltip>,
    ...(canReply == true
      ? [
          <span onClick={() => setShowCommentBox(prev => !prev)} style={{ fontSize: '1rem' }} key="comment-basic-reply-to">
            Reply
          </span>,
        ]
      : []),
    // ...(user.ClientID == commentItem.clientId
    //   ? [
    //       <Tooltip key="comment-basic-edit" title="Edit">
    //         <span onClick={() => setIsEditing(true)} style={{ fontSize: '1rem', cursor: 'pointer' }}>
    //           Edit
    //         </span>
    //       </Tooltip>,
    //       <Tooltip key="comment-basic-delete" title="Delete">
    //         <span onClick={handleDelete} style={{ fontSize: '1rem', cursor: 'pointer' }}>
    //           Delete
    //         </span>
    //       </Tooltip>,
    //     ]
    //   : []),
  ];

  const getName = fullName => {
    let split = fullName.split(' ');
    split[0] = split[0][0].toUpperCase() + split[0].slice(1, split[0].length);
    split[split.length - 1] = `${split[split.length - 1][0].toUpperCase()}.`;
    let fixedName = [split[0], split[split.length - 1]];
    return fixedName.join(' ');
  };
  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={() => setIsEditing(true)}>
        <EditFilled style={{ fontSize: '1rem', cursor: 'pointer' }} />
        <span style={{ fontSize: '1rem', cursor: 'pointer' }}>Edit</span>
      </Menu.Item>
      <Menu.Item onClick={showDeleteModal} key="1">
        <DeleteFilled style={{ fontSize: '1rem', cursor: 'pointer' }} />
        <span style={{ fontSize: '1rem', cursor: 'pointer' }}>Delete</span>
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      {!isEditing ? (
        <Comment
          actions={actions}
          author={<span style={{ fontSize: '1rem', color: themeContext.colors.brandDarkBlue }}>{getName(commentItem.name)}</span>}
          avatar={<Avatar icon={<UserOutlined />} src={commentItem.picUrl} />}
          content={
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <div style={{ width: '95%', fontSize: '1rem' }}>
                <p>{commentItem.content}</p>
              </div>
              {user.ClientID == commentItem.clientId ? (
                <div style={{ width: '5%', textAlign: 'right', fontSize: '1.25rem' }}>
                  <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
                    <MoreOutlined />
                  </Dropdown>
                </div>
              ) : (
                <div style={{ width: '5%', textAlign: 'right', fontSize: '1.25rem' }} />
              )}
            </div>
          }
          datetime={<span style={{ fontSize: '1rem' }}>{moment(commentItem.createdAt).fromNow()}</span>}
        >
          {canReply && showCommentBox && (
            <CommentBox parentId={commentItem._id} videoId={commentItem.videoId} onCommentAdd={onCommentAdd} videoDetails={videoDetails}></CommentBox>
          )}
          {comments.length > 0 && (
            <span
              onClick={() => setShowReplies(prev => !prev)}
              style={{ fontSize: '1rem', cursor: 'pointer', color: themeContext.colors.brandLightBlue }}
            >
              {showReplies ? <CaretUpFilled /> : <CaretDownFilled />}
              {showReplies ? 'Hide Replies' : 'Show Replies'}
            </span>
          )}
          {canReply && showReplies && comments.length > 0 && (
            <CommentList videoDetails={videoDetails} setComments={setComments} comments={comments} canReply={false} />
          )}
        </Comment>
      ) : (
        <CommentBox
          videoDetails={videoDetails}
          onCancel={() => setIsEditing(false)}
          videoId={commentItem.videoId}
          onCommentAdd={onCommentAdd}
          comment={commentItem}
          onCommentEdit={onCommentEdit}
        >
          {comments.length > 0 && (
            <span
              onClick={() => setShowReplies(prev => !prev)}
              style={{ fontSize: '1rem', cursor: 'pointer', color: themeContext.colors.brandLightBlue }}
            >
              {showReplies ? <CaretUpFilled /> : <CaretDownFilled />}
              {showReplies ? 'Hide Replies' : 'Show Replies'}
            </span>
          )}
          {canReply && showReplies && comments.length > 0 && (
            <CommentList videoDetails={videoDetails} setComments={setComments} comments={comments} canReply={false} />
          )}
        </CommentBox>
      )}
    </>
  );
};

export default CommentComp;
