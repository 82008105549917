import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Card } from "antd";
import { RightOutlined } from "@ant-design/icons";

const LoginWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
`;

const CardWrapper = styled.div`
  width: 100%;
`;

const CardTitle = styled.div`
  font-size: ${(props: any) => props.theme.fontSizes.large};
  font-weight: 700;
  text-align: center;
  color: ${(props: any) => props.theme.colors.brandDarkGrey};
`;
const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em;
  margin: 1em 0;
  cursor: pointer;
  border: 2px solid ${(props: any) => props.theme.colors.coolGrey};
  border-radius: 0.5rem;
  span {
    margin: 0;
  }
  &:hover {
    color: ${(props: any) => props.theme.colors.brandDarkBlue};
    border: 2px solid ${(props: any) => props.theme.colors.brandDarkGrey};
  }
`;
interface Props {}

const Migrated = (props: Props) => {
  const getApp = async () => {
    window.location.href = `https://www.tpasc.ca/tpasc-app`;
  };

  return (
    <LoginWrapper>
      <div style={{ width: "80%", maxWidth: "300px", marginBottom: "4em" }}>
        <img
          src={require("assets/logos/PanAm_logo_RGB.png")}
          style={{ width: "100%" }}
        />
      </div>
      {/* <h1 style={{ textAlign: "center" }}>Self Service Login</h1> */}
      <CardWrapper>
        <Card
          title={<CardTitle></CardTitle>}
          style={{
            maxWidth: "400px",
            minWidth: "320px",
            width: "90%",
            borderRadius: "5px",
            backgroundColor: "#fff",
            margin: "0 auto",
            boxShadow: "5px 5px 10px 0px rgba(0,0,0,0.3)",
          }}
        >
          <h2 style={{ textAlign: "center", fontSize: "1.15rem" }}>
            We are no longer supporting this app. Please install the Tpasc
            Fitness app.
          </h2>
          <ButtonsWrapper>
            <StyledButton onClick={getApp}>
              <span style={{ fontSize: "1.125rem" }}>Get App</span>
              <RightOutlined style={{ fontSize: "1.5rem", margin: 0 }} />
            </StyledButton>
          </ButtonsWrapper>
        </Card>
      </CardWrapper>
    </LoginWrapper>
  );
};

export default Migrated;
