import React, { useState, useEffect } from 'react';
import { List, Divider, Tag } from 'antd';
import VideoItem from '../../../component/VideoItem/VideoItem';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import VideoFilterModal from '../../../component/VideoFilterModal/VideoFilterModal';
import { useQuery } from '@apollo/react-hooks';
import { VIDEOS_FILTER_QUERY } from 'apollo/video/queries';
import LoadingSpinner from 'component/Loader';
import { filter } from 'lodash';
import { VideoListWrapper, TitleWrapper } from './VideoListStyles';

const VideoList: React.FC<any> = ({ showFilterModal, setShowFilterModal }) => {
  const [trainerId, setTrainerId] = useState<String | null>(null);
  const [activityId, setActivityId] = useState<String | null>(null);
  const [filterField, setFilterField] = useState<Object | null>(null);
  const [intensity, setIntensity] = useState<Object | null>(null);
  const [equipments, setEquipments] = useState([]);
  const [duration, setDuration] = useState<Number | null>(null);
  const location: any = useLocation();
  const history = useHistory();
  const { activity } = useParams();
  useEffect(() => {
    if (location) {
      if (location.state) {
        // console.log('location state', location.state);
        if (location.state.filterField) {
          setFilterField(location.state.filterField);
        }
        if (location.state.activityId) {
          // console.log('here');
          setActivityId(location.state.activityId);
        }
        if (location.state.trainerId) {
          setTrainerId(location.state.trainerId);
        }
        if (location.state.intensity) {
          setIntensity(location.state.intensity);
        }
        if (location.state.equipments) {
          setEquipments(location.state.equipments);
        }
        if (location.state.duration) {
          setDuration(location.state.duration);
        }
      }
    }
  }, [location]);

  const { loading, error, data, refetch } = useQuery(VIDEOS_FILTER_QUERY, {
    variables: {
      videosFilterInput: {
        ...(activityId && { activity: activityId }),
        ...(trainerId && { trainer: trainerId }),
        ...(intensity && { intensity: intensity }),
        ...(equipments.length > 0 && { equipments: equipments }),
        ...(duration && duration > 0 && { duration: duration }),
      },
    },
  });
  if (loading) return <LoadingSpinner />;
  // if (data) {
  //   console.log('filter data', data);
  // }

  const onClickItem = (item) => {
    // console.log('item clicker', item.title);
    history.push({
      pathname: '/video',
      search: `?vid=${item._id}`,
      state: { vid: item },
    });
  };

  const onFilterDone = (filters) => {
    // console.log('filter done video list', filters);
    if (
      filters.activity !== 'all' ||
      filters.trainer !== 'all' ||
      filters.date !== null ||
      filters.intensity !== 'all' ||
      filters.duration !== 0 ||
      filters.equipments.length > 0
    ) {
      let ids: any = {};
      if (filters.activityId) {
        ids = { ...ids, activityId: filters.activityId };
      }
      if (filters.trainerId) {
        ids = { ...ids, trainerId: filters.trainerId };
      }
      if (filters.intensity !== 'all') {
        ids = { ...ids, intensity: filters.intensity };
      }
      if (filters.equipments.length > 0) {
        ids = { ...ids, equipments: filters.equipments };
      }
      if (filters.duration > 0) {
        ids = { ...ids, duration: filters.duration };
      }
      let tempFilterField = {
        date: filters.date == null ? 'all' : filters.date,
        trainer: filters.trainer,
        activity: filters.activity,
        intensity: filters.intensity,
        equipments: filters.equipments.length > 0 ? filters.equipments.join(',') : 'all',
        duration: filters.duration > 0 ? filters.duration : 'all',
      };

      setFilterField(tempFilterField);
      // console.log('ids');
      refetch({
        videosFilterInput: { ...ids },
      });
    } else {
      setFilterField(null);
      history.push({
        pathname: '/',
      });
    }
    setShowFilterModal(false);
  };
  const onFilterCancel = () => {
    // console.log('filter cancelled');
    setShowFilterModal(false);
  };

  return (
    <VideoListWrapper>
      <div>
        <TitleWrapper>{activity}</TitleWrapper>
      </div>
      {filterField && (
        <div style={{ marginBottom: '10px' }}>
          {Object.keys(filterField).map((keyName, i) => (
            <Tag onClick={() => setShowFilterModal(true)} key={keyName}>{`${keyName.replace(/^./, keyName[0].toUpperCase())}: ${
              filterField[keyName]
            }`}</Tag>
          ))}
        </div>
      )}
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 5,
          xxl: 5,
        }}
        dataSource={data.videosFilter.videos}
        renderItem={(item) => (
          <List.Item>
            <VideoItem onClick={onClickItem} item={item} />
          </List.Item>
        )}
      />
      <VideoFilterModal onDone={onFilterDone} onCancel={onFilterCancel} visible={showFilterModal} />
    </VideoListWrapper>
  );
};

export default VideoList;
