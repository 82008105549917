import React, { useContext, useEffect, useState } from 'react';
import { getUserData, setUserData, getToken } from 'helper';
import AntdButton from 'component/elements/AntdButton';
import { Button, notification, Select } from 'antd';
import LoadingSpinner from 'component/Loader';
import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';
import './../../../component/Layout/Header/notificationStyles.css';

const { Option } = Select;

const VideoSession = () => {
  const theme = useContext(ThemeContext);
  const history = useHistory();
  const [user, setUser] = useState<any>(JSON.parse(getUserData()) || null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  const [trainer, setTrainer] = useState<string | undefined>(undefined);

  useEffect(() => {
    const token = getToken();
    if (!token) {
      setLoading(false);
      setError('No token');
    } else {
      setLoading(false);
    }
  }, []);

  const onSubmit = () => {
    if (!trainer) {
      notification.error({
        message: 'No Trainer',
        duration: 0,
        description: 'You have not selected trainer',
        className: 'notification',
      });
      return;
    }
    history.push(`/waitingroom?trainer=${trainer}`);
    // signupCoaching({
    //   variables: {
    //     trainer: trainer,
    //   },
    // })
    //   .then(data => {
    //     console.log('success');
    //     if (data.data.ezSignupPersonalCoaching) {
    //       notification.success({
    //         message: 'Success',
    //         duration: 0,
    //         description: 'Your request is success.',
    //         className: 'notification',
    //       });
    //     }
    //   })
    //   .catch(err => {
    //     console.error(err);
    //     notification.error({
    //       message: 'Fail',
    //       duration: 0,
    //       description: 'You request did not succeed',
    //       className: 'notification',
    //     });
    //   });
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <span style={{ padding: 15 }}>{error}</span>;
  return (
    <div style={{ padding: 20 }}>
      <div style={{ marginBottom: 15, textAlign: 'center' }}>
        <p>
          Welcome to our virtual training. Each trainer has their own room. Once you select your trainer, you will be placed in the waiting room until
          they let you in.
        </p>
        <h3 style={{ margin: 0 }}>
          {user?.firstName} {user?.lastName}
        </h3>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}>
        <Select size="large" style={{ marginBottom: 20 }} placeholder="Select Trainer" value={trainer} onChange={val => setTrainer(val)}>
          <Option key="Ryan A." value="Ryan A.">
            Ryan A.
          </Option>
          <Option key="Tanisha C." value="Tanisha C.">
            Tanisha C.
          </Option>
        </Select>
        <AntdButton size="large" type="primary" style={{ marginBottom: 15 }} onClick={onSubmit}>
          Submit
        </AntdButton>
      </div>
    </div>
  );
};

export default VideoSession;
