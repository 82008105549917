import React, { useContext, useEffect, useState } from 'react';
import { getUserData, setUserData, getToken } from 'helper';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { MY_EZ_SCHEDULE_QUERY, MY_EZ_SCHEDULE_SELF_SERVICE_QUERY } from 'apollo/ezSchedule/queries';
import moment from 'moment';
import AntdTable from 'component/elements';
import { Button, notification } from 'antd';
import LoadingSpinner from 'component/Loader';
import ScheduleDateSelect from 'component/ScheduleDateSelect/ScheduleDateSelect';
import { CANCEL_BOOKED_EZ_SESSION_MUTATION, CANCEL_WAITLISTED_EZ_SESSION_MUTATION } from 'apollo/ezSchedule/mutations';
import './../../../component/Layout/Header/notificationStyles.css';
import { CloseCircleFilled, PlusOutlined } from '@ant-design/icons';
import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';

const MyEzSchedule = () => {
  const theme = useContext(ThemeContext);
  const history = useHistory();
  const [ezSchedule, setEzSchedule] = useState<any>(null);
  const [user, setUser] = useState<any>(JSON.parse(getUserData()) || null);
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [cancelBooked] = useMutation(CANCEL_BOOKED_EZ_SESSION_MUTATION);
  const [cancelWaitlisted] = useMutation(CANCEL_WAITLISTED_EZ_SESSION_MUTATION);
  // useQuery(MY_EZ_SCHEDULE_SELF_SERVICE_QUERY, {
  //   variables: {
  //     startDate: moment().startOf('day').toISOString(),
  //     endDate: moment().add(2, 'days').endOf('day').toISOString(),
  //     page: 1,
  //   },
  //   onCompleted: async data => {
  //     setLoading(false);
  //     console.log('myschedule selfservice', data);
  //     if (data.myEzScheduleSelfService) {
  //       let obj = {
  //         ClientID: data.myEzScheduleSelfService.user.clientId,
  //         firstName: data.myEzScheduleSelfService.user.firstName,
  //         lastName: data.myEzScheduleSelfService.user.lastName,
  //         MemberPhotoUrl: data.myEzScheduleSelfService.user.memberPhotoUrl,
  //       };
  //       setEzSchedule(data.myEzScheduleSelfService.myEzSchedule);
  //       if (!user) {
  //         console.log('user set');
  //         await setUserData(JSON.stringify(obj));
  //         setUser(obj);
  //       }
  //     } else {
  //       setError('Error in getting schedule.');
  //     }
  //   },
  //   onError: err => {
  //     setLoading(false);
  //     setError('Error in getting schedule.');
  //     console.log('error', err);
  //   },
  // });
  useQuery(MY_EZ_SCHEDULE_QUERY, {
    variables: {
      startDate: moment().startOf('day').toISOString(),
      endDate: moment().add(2, 'days').endOf('day').toISOString(),
      page: 1,
    },
    onCompleted: async data => {
      setLoading(false);
      console.log('myschedule', data);
      if (data.myEzSchedule) {
        let obj = {
          ClientID: data.myEzSchedule.user.clientId,
          firstName: data.myEzSchedule.user.firstName,
          lastName: data.myEzSchedule.user.lastName,
          MemberPhotoUrl: data.myEzSchedule.user.memberPhotoUrl,
        };
        setEzSchedule(data.myEzSchedule.myEzSchedule);
        if (!user) {
          console.log('user set');
          await setUserData(JSON.stringify(obj));
          setUser(obj);
        }
      } else {
        setError('Error in getting schedule.');
      }
    },
    onError: err => {
      setLoading(false);
      setError('Error in getting schedule.');
      console.log('error', err);
    },
  });
  // const setLoginToken = async token => {
  //   await setToken(token);
  //   myEzSchedule({
  //     variables: {
  //       startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
  //       endDate: moment().add(1, 'month').format('YYYY-MM-DD'),
  //       page: 1,
  //     },
  //   });
  // };

  useEffect(() => {
    const token = getToken();
    if (!token) {
      setLoading(false);
      setError('No token');
    }
  }, []);
  const handleCancel = session => {
    setTableLoading(true);
    if (session.myBookingStatus.toLowerCase() == 'scheduled') {
      cancelBooked({
        variables: {
          sessionId: session.id,
          bookingId: session.myBookingID,
        },
      })
        .then(({ data }) => {
          if (data.cancelBookedEzSession) {
            let temp = ezSchedule.filter(item => item.id !== session.id);
            setEzSchedule(temp);
            notification.success({
              message: 'Cancel Success',
              duration: 0,
              description: 'Booked Session has been cancelled successfully.',
              className: 'notification',
            });
          }
          setTableLoading(false);
        })
        .catch(err => {
          let errorMessage = err.toString().replace('Error: GraphQL error:', '');
          if (errorMessage == 'Error: The class can not be canceled at this time.') {
            errorMessage = 'Please contact the centre directly 416-283-5222.';
          }
          notification.error({
            message: 'Cancel Failed',
            duration: 0,
            description: errorMessage,
            className: 'notification',
          });
          setTableLoading(false);
        });
    } else if (session.myBookingStatus.toLowerCase() == 'waitlist') {
      cancelWaitlisted({
        variables: {
          sessionId: session.id,
          bookingId: session.myBookingID,
        },
      })
        .then(({ data }) => {
          if (data.cancelWaitlistedEzSession) {
            let temp = ezSchedule.filter(item => item.id !== session.id);
            setEzSchedule(temp);
            notification.success({
              message: 'Cancel Success',
              duration: 0,
              description: 'Waitlisted Session has been cancelled successfully.',
              className: 'notification',
            });
          }
          setTableLoading(false);
        })
        .catch(err => {
          let errorMessage = err.toString().replace('Error: GraphQL error:', '');
          if (errorMessage == 'Error: The class can not be canceled at this time.') {
            errorMessage = 'Please contact the centre directly 416-283-5222.';
          }
          notification.error({
            message: 'Cancel Failed',
            duration: 0,
            description: errorMessage,
            className: 'notification',
          });
          setTableLoading(false);
        });
    }
  };
  const columns = [
    {
      key: 'time',
      title: 'Time',
      width: '35%',
      render: record => <p>{`${moment(record.startTimeUTC).format('h:mm a')} - ${moment(record.endTimeUTC).format('h:mm a')}`}</p>,
    },
    {
      key: 'actvity',
      title: 'Activity',
      width: '40%',
      render: record => {
        return <p>{record.title}</p>;
      },
    },
    {
      key: 'status',
      title: 'Status',
      width: '15%',
      render: record => <p>{record.myBookingStatus}</p>,
    },
    {
      key: 'cancel',
      title: 'Cancel',
      width: '10%',
      render: record => (
        <Button shape="circle" style={{ fontSize: '1rem' }} type="text" onClick={() => handleCancel(record)}>
          <CloseCircleFilled style={{ color: 'red', fontSize: '2rem' }} />
        </Button>
      ),
    },
  ];

  if (loading) return <LoadingSpinner />;
  const handleChangeDay = day => {
    switch (day) {
      case 'today':
        setSelectedDate(moment());
        break;
      case 'tomorrow':
        setSelectedDate(moment().add(1, 'day'));
        break;
      case 'dayAfter':
        setSelectedDate(moment().add(2, 'days'));
        break;
      default:
        break;
    }
  };
  return (
    <div style={{ marginBottom: 15 }}>
      <div style={{ background: theme.colors.brandDarkBlue, padding: '10px 0', textAlign: 'center' }}>
        <h3 style={{ color: '#fff', margin: 0 }}>My Schedule</h3>
      </div>
      {error && <span>{error}</span>}
      {ezSchedule && (
        <>
          <ScheduleDateSelect disabled={tableLoading} selectedDate={selectedDate} handleChangeDay={handleChangeDay} />
          <div style={{ marginLeft: 5, marginRight: 5 }}>
            <AntdTable
              loading={tableLoading}
              pagination={false}
              rowKey={record => record.id}
              // scroll={{ x: true }}
              columns={columns}
              dataSource={ezSchedule.filter(item => moment(item.startTimeUTC).format('YYYY-MM-DD') == moment(selectedDate).format('YYYY-MM-DD'))}
            />
          </div>
        </>
      )}
      <div style={{ position: 'fixed', bottom: '25px', right: '25px', cursor: 'pointer' }}>
        <Button
          onClick={() => history.push('/ezsessions')}
          type="primary"
          shape="circle"
          size="large"
          style={{ height: '50px', width: '50px', background: theme.colors.brandDarkBlue, borderColor: theme.colors.brandDarkBlue }}
        >
          <PlusOutlined style={{ fontSize: '35px' }} />
        </Button>
      </div>
    </div>
  );
};

export default MyEzSchedule;
