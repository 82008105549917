import React, { useEffect, useState } from 'react';
import { Button, Input, Select, Form, notification } from 'antd';
import LoadingSpinner from 'component/Loader';
import { getToken, getUserData } from 'helper';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import AntdButton from 'component/elements/AntdButton';
import Barcode from 'react-barcode';
import { BARCODE_QUERY } from 'apollo/ezSchedule/queries';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { LINK_BARCODE_MUTATION, REFRESH_BARCODE_MUTATION, UNLINK_BARCODE_MUTATION } from 'apollo/ezSchedule/mutations';
import './../../../component/Layout/Header/notificationStyles.css';
const { Option } = Select;

const BarcodeScreen = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [user, setUser] = useState<any>(JSON.parse(getUserData()) || null);
  const [intervals, setIntervals] = useState<any>(null);
  const [cardType, setCardType] = useState<any>(undefined);
  const [linkMore, setLinkMore] = useState(false);
  const [accountLinked, setAccountLinked] = useState(false);
  const [barcodes, setBarcodes] = useState<any>(null);
  const [currentBarcode, setCurrentBarcode] = useState<any>(null);
  const [fetching, setFetching] = useState(true);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<any>(null);
  const [linkBarcodeMutation] = useMutation(LINK_BARCODE_MUTATION);
  const [unlinkBarcodeMutation] = useMutation(UNLINK_BARCODE_MUTATION);
  const [refreshBarcodeMutation] = useMutation(REFRESH_BARCODE_MUTATION);
  useQuery(BARCODE_QUERY, {
    variables: {
      memberId: user && user.virtuagymMemberId ? +user.virtuagymMemberId : null,
    },
    onCompleted: data => {
      console.log('data', data);
      setFetching(false);
      if (data && data.barcode) {
        setAccountLinked(data.barcode.barcodes.length > 0);
        setBarcodes(data.barcode.barcodes);
        setCurrentBarcode(data.barcode.barcodes.length > 0 ? 0 : null);
      }
    },
    onError: err => {
      setError(err);
      setFetching(false);
    },
  });
  useEffect(() => {
    const token = getToken();
    // console.log('user', user);
    if (!token) {
      setLoading(false);
      setError('No token');
    } else {
      setLoading(false);
    }
    return () => {
      if (intervals) {
        clearInterval(intervals);
      }
    };
  }, []);

  const refetchBarcodes = recBarcodes => {
    if (recBarcodes && recBarcodes.length > 0) {
      console.log('here');
      refreshBarcodeMutation({
        variables: {
          memberId: user && user.virtuagymMemberId ? +user.virtuagymMemberId : null,
        },
      })
        .then(result => {
          // setLinkMore(false);
          setSubmitting(false);
          console.log('refresh result', result);
          if (result.data.refreshBarcode) {
            setAccountLinked(result.data.refreshBarcode.barcodes.length > 0);
            setBarcodes(result.data.refreshBarcode.barcodes);
            if (currentBarcode > result.data.refreshBarcode.barcodes.length) {
              setCurrentBarcode(result.data.refreshBarcode.barcodes.length > 0 ? 0 : null);
            }
          }
        })
        .catch(err => {
          console.log('err in link', err);
          setSubmitting(false);
          notification.error({
            message: 'Link Barcode Failed',
            duration: 0,
            className: 'notification',
          });
        });
    }
  };

  useEffect(() => {
    if (intervals) clearInterval(intervals);
    if (barcodes && barcodes.length > 0) {
      let intv = setInterval(function () {
        refetchBarcodes(barcodes);
      }, 30000);
      setIntervals(intv);
    }
  }, [barcodes]);

  const onUnlinkBarcode = () => {
    setSubmitting(true);
    unlinkBarcodeMutation({
      variables: {
        memberId: user && user.virtuagymMemberId ? +user.virtuagymMemberId : null,
        barcode: barcodes[currentBarcode],
      },
    })
      .then(result => {
        setSubmitting(false);
        console.log('unlink result', result);
        if (result.data.unlinkBarcode) {
          setAccountLinked(result.data.unlinkBarcode.barcodes.length > 0);
          setBarcodes(result.data.unlinkBarcode.barcodes);
          setCurrentBarcode(result.data.unlinkBarcode.barcodes.length > 0 ? 0 : null);
        }
      })
      .catch(err => {
        console.log('err in unlink', err);
        setSubmitting(false);
        notification.error({
          message: 'Unlink Barcode Failed',
          duration: 0,
          className: 'notification',
        });
      });
  };
  const onLinkAccount = () => {
    setSubmitting(true);
    // let input={
    //   id1:,
    //   id2:user&&user.MembershipNumber,
    // }
    form
      .validateFields()
      .then(values => {
        let input = {};
        if (cardType == 'Member') {
          input = {
            id1: user && `${user.ClientID}`,
          };
        } else {
          input = {
            id1: values.id1,
            id2: values.id2,
          };
        }
        linkBarcodeMutation({
          variables: {
            memberId: user && user.virtuagymMemberId ? +user.virtuagymMemberId : null,
            type: cardType,
            input: input,
          },
        })
          .then(result => {
            form.setFieldsValue({ id1: undefined, id2: undefined });
            setLinkMore(false);
            setSubmitting(false);
            // console.log('link result', result);
            if (result.data.linkBarcode) {
              setAccountLinked(result.data.linkBarcode.barcodes.length > 0);
              setBarcodes(result.data.linkBarcode.barcodes);
              setCurrentBarcode(result.data.linkBarcode.barcodes.length > 0 ? 0 : null);
            }
          })
          .catch(err => {
            // console.log('err in link', err);
            setSubmitting(false);
            notification.error({
              message: 'Link Barcode Failed',
              description: err.toString().replace('Error: GraphQL error: ', ''),
              duration: 0,
              className: 'notification',
            });
          });
      })
      .catch(err => {
        setSubmitting(false);
      });
  };

  if (loading || fetching) return <LoadingSpinner />;

  if (linkMore) {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingTop: 10 }}>
          <Button type="text" onClick={() => setLinkMore(false)}>
            <LeftOutlined style={{ color: '#000', fontSize: 18 }} />
          </Button>
        </div>
        {error ? (
          <span style={{ padding: 15 }}>{error}</span>
        ) : (
          <div style={{ padding: '5px 15px' }}>
            <div style={{ marginBottom: 15, textAlign: 'center' }}>
              <h3 style={{ margin: 0 }}>Link Account</h3>
            </div>
            <div>
              <div>
                <Select
                  size="large"
                  value={cardType}
                  placeholder="Select Card Type"
                  onChange={val => setCardType(val)}
                  style={{ width: '100%', marginBottom: 15 }}
                >
                  <Option value="Member">TPASC Member Card</Option>
                  <Option value="Student">U of T Tcard</Option>
                  <Option value="City">City of Toronto Keytag</Option>
                  <Option value="Track Walker">TPASC Track Keytag</Option>
                  <Option value="League/Events">League/Events</Option>
                </Select>
                <Form form={form}>
                  {cardType == 'Member' && (
                    <div>
                      <p>
                        MEMBERS PLEASE NOTE: If you have just created your account, it can take upto 24 hours for you to be able to add your
                        membership number in the app, please try again tomorrow.
                      </p>
                    </div>
                  )}
                  {cardType == 'Student' && (
                    <div>
                      <Form.Item name="id1" rules={[{ required: true, message: 'Required' }]}>
                        <Input size="large" placeholder="Library Number" />
                      </Form.Item>
                      <Form.Item name="id2" rules={[{ required: true, message: 'Required' }]}>
                        <Input size="large" placeholder="Student/Employee Number" />
                      </Form.Item>
                    </div>
                  )}
                  {cardType == 'City' && (
                    <div>
                      <Form.Item name="id1" rules={[{ required: true, message: 'Required' }]}>
                        <Input size="large" placeholder="Last Name" />
                      </Form.Item>
                      <Form.Item name="id2" rules={[{ required: true, message: 'Required' }]}>
                        <Input size="large" placeholder="Keytag Number" />
                      </Form.Item>
                    </div>
                  )}
                  {cardType == 'Track Walker' && (
                    <div>
                      <Form.Item name="id1" rules={[{ required: true, message: 'Required' }]}>
                        <Input size="large" placeholder="Last Name" />
                      </Form.Item>
                      <Form.Item name="id2" rules={[{ required: true, message: 'Required' }]}>
                        <Input size="large" placeholder="Keytag Number" />
                      </Form.Item>
                    </div>
                  )}
                  {cardType == 'League/Events' && (
                    <div>
                      <Form.Item name="id1" rules={[{ required: true, message: 'Required' }]}>
                        <Input size="large" placeholder="Group ID" />
                      </Form.Item>
                      <Form.Item name="id2" rules={[{ required: true, message: 'Required' }]}>
                        <Input size="large" placeholder="Passphrase" />
                      </Form.Item>
                    </div>
                  )}
                </Form>
                {cardType && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <AntdButton loading={submitting} type="primary" size="large" style={{ marginBottom: 15 }} onClick={onLinkAccount}>
                      Link Account
                    </AntdButton>
                    <p>
                      PLEASE NOTE: By adding your card to this app, it will deactivate your physical membership card. Should you wish to return to
                      your membership card you may delete it from the app, but will take up to 24 hours for your membership card to be reactivated.
                      You are still bound by all terms and conditions of Toronto Pan Am Sports Centre policies and procedures.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  if (accountLinked && barcodes.length > 0) {
    return (
      <div style={{ padding: 0 }}>
        {/* <div style={{ paddingTop: 10 }}>
      <Button type="text" onClick={() => history.goBack()}>
        <LeftOutlined style={{ color: '#000', fontSize: 18 }} />
      </Button>
    </div> */}
        {error ? (
          <span style={{ padding: 15 }}>{error}</span>
        ) : (
          <div style={{ padding: '15px 15px' }}>
            <div>
              <div>
                <div style={{ textAlign: 'center', marginBottom: 20 }}>
                  <Barcode value={`${barcodes[currentBarcode]}`} text={`${barcodes[currentBarcode]?.slice(0, -3)}`} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 15 }}>
                  <AntdButton
                    disabled={currentBarcode == 0 ? true : false}
                    onClick={() => {
                      setCurrentBarcode(prev => prev - 1);
                    }}
                    style={{ width: '45%' }}
                    type="primary"
                  >
                    Prev
                  </AntdButton>
                  <AntdButton
                    disabled={currentBarcode == barcodes.length - 1 ? true : false}
                    onClick={() => {
                      setCurrentBarcode(prev => prev + 1);
                    }}
                    style={{ width: '45%' }}
                    type="primary"
                  >
                    Next
                  </AntdButton>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <AntdButton
                    disabled={submitting}
                    type="primary"
                    size="large"
                    style={{ marginBottom: 15 }}
                    onClick={() => {
                      if (barcodes.length >= 3) {
                        notification.error({
                          message: 'Cannot Link More Than 3',
                          duration: 0,
                          className: 'notification',
                        });
                        return;
                      }
                      setLinkMore(true);
                    }}
                  >
                    Link More
                  </AntdButton>
                  <AntdButton loading={submitting} danger type="primary" size="large" style={{ marginBottom: 15 }} onClick={onUnlinkBarcode}>
                    Unlink Barcode
                  </AntdButton>
                  <p>
                    PLEASE NOTE: By adding your card to this app, it will deactivate your physical membership card. Should you wish to return to your
                    membership card you may delete it from the app, but will take up to 24 hours for your membership card to be reactivated. You are
                    still bound by all terms and conditions of Toronto Pan Am Sports Centre policies and procedures.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div style={{ padding: 0 }}>
      {/* <div style={{ paddingTop: 10 }}>
        <Button type="text" onClick={() => history.goBack()}>
          <LeftOutlined style={{ color: '#000', fontSize: 18 }} />
        </Button>
      </div> */}
      {error ? (
        <span style={{ padding: 15 }}>{error}</span>
      ) : (
        <div style={{ padding: '5px 15px' }}>
          <div style={{ marginBottom: 15, textAlign: 'center' }}>
            <h3 style={{ margin: 0 }}>Link Account</h3>
          </div>
          <div>
            <div>
              <Select
                size="large"
                value={cardType}
                placeholder="Select Card Type"
                onChange={val => setCardType(val)}
                style={{ width: '100%', marginBottom: 15 }}
              >
                <Option value="Member">TPASC Member Card</Option>
                <Option value="Student">U of T Tcard</Option>
                <Option value="City">City of Toronto Keytag</Option>
                <Option value="Track Walker">TPASC Track Keytag</Option>
                <Option value="League/Events">League/Events</Option>
              </Select>
              <Form form={form}>
                {cardType == 'Member' && (
                  <div>
                    <p>
                      MEMBERS PLEASE NOTE: If you have just created your account, it can take upto 24 hours for you to be able to add your membership
                      number in the app, please try again tomorrow.
                    </p>
                  </div>
                )}
                {cardType == 'Student' && (
                  <div>
                    <Form.Item name="id1" rules={[{ required: true, message: 'Required' }]}>
                      <Input size="large" placeholder="Library Number" />
                    </Form.Item>
                    <Form.Item name="id2" rules={[{ required: true, message: 'Required' }]}>
                      <Input size="large" placeholder="Student/Employee Number" />
                    </Form.Item>
                  </div>
                )}
                {cardType == 'City' && (
                  <div>
                    <Form.Item name="id1" rules={[{ required: true, message: 'Required' }]}>
                      <Input size="large" placeholder="Last Name" />
                    </Form.Item>
                    <Form.Item name="id2" rules={[{ required: true, message: 'Required' }]}>
                      <Input size="large" placeholder="Keytag Number" />
                    </Form.Item>
                  </div>
                )}
                {cardType == 'Track Walker' && (
                  <div>
                    <Form.Item name="id1" rules={[{ required: true, message: 'Required' }]}>
                      <Input size="large" placeholder="Last Name" />
                    </Form.Item>
                    <Form.Item name="id2" rules={[{ required: true, message: 'Required' }]}>
                      <Input size="large" placeholder="Keytag Number" />
                    </Form.Item>
                  </div>
                )}
                {cardType == 'League/Events' && (
                  <div>
                    <Form.Item name="id1" rules={[{ required: true, message: 'Required' }]}>
                      <Input size="large" placeholder="Group ID" />
                    </Form.Item>
                    <Form.Item name="id2" rules={[{ required: true, message: 'Required' }]}>
                      <Input size="large" placeholder="Passphrase" />
                    </Form.Item>
                  </div>
                )}
              </Form>
              {cardType && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <AntdButton loading={submitting} type="primary" size="large" style={{ marginBottom: 15 }} onClick={onLinkAccount}>
                    Link Account
                  </AntdButton>
                  <p>
                    PLEASE NOTE: By adding your card to this app, it will deactivate your physical membership card. Should you wish to return to your
                    membership card you may delete it from the app, but will take up to 24 hours for your membership card to be reactivated. You are
                    still bound by all terms and conditions of Toronto Pan Am Sports Centre policies and procedures.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BarcodeScreen;
