import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { EZ_CLIENT_SIGNATURE_LOGGN } from 'apollo/user/mutations';
import LoadingSpinner from 'component/Loader';
import { setToken, setUserData, removeToken } from 'helper';

const SingatureLogin = () => {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [ezClientSignatureLogin] = useMutation(EZ_CLIENT_SIGNATURE_LOGGN);
  const [error, setError] = useState<any>(null);

  const signatureLogin = async (signature, redirectUri = null, virtuagymMemberId) => {
    let splitSign = signature.split('_');
    if (!splitSign[0] || !splitSign[1]) {
      setLoading(false);
      setError('no signature provided');
      return;
    }
    ezClientSignatureLogin({
      variables: {
        clientId: splitSign[0],
        memberId: splitSign[1],
      },
    })
      .then(async result => {
        console.log('login success', result);
        if (result.data && result.data.ezClientSignatureLogin) {
          await removeToken();
          const { jwt, ...rest } = result.data.ezClientSignatureLogin;
          await setToken(jwt);
          await setUserData(JSON.stringify({ ...rest, virtuagymMemberId }));
        }
        setLoading(false);
        if (redirectUri) {
          history.push(`/${redirectUri}`);
        } else {
          history.push('/');
        }
      })
      .catch(err => {
        console.log('login error', err);
        setError(err.message.toString().replace('GraphQL error:', ''));
        setLoading(false);
      });
  };

  useEffect(() => {
    if (location) {
      let queryParams = new URLSearchParams(location.search);
      let signature = queryParams.get('signature');
      console.log('signature', signature);
      let redirectUri: any = queryParams.get('redirect');
      console.log('redirect', redirectUri);
      let virtuagymMemberId: any = queryParams.get('virtuagymMemberId');
      if (signature) {
        // console.log('token ', token);
        signatureLogin(signature, redirectUri, virtuagymMemberId);
      } else {
        setLoading(false);
        setError('No Signatue Provided');
      }
    }
  }, [location]);

  if (loading) return <LoadingSpinner />;

  return <div>{error && <span>{error}</span>}</div>;
};

export default SingatureLogin;
