import React, { useState, useEffect } from 'react';
import VideoFilterModal from '../../../component/VideoFilterModal/VideoFilterModal';
import { Tag, List } from 'antd';
import { useHistory, Link, useLocation } from 'react-router-dom';
import VideoItem from '../../../component/VideoItem/VideoItem';
import { useQuery } from '@apollo/react-hooks';
import { VIDEOS_ALL_ACTIVITY_QUERY } from 'apollo/video/queries';
import LoadingSpinner from 'component/Loader';
import { NetworkStatus } from 'apollo-boost';
import VideoListScroll from 'component/VideoListScroll/VideoListScroll';
import { HomeWrapper, TitleWrapper } from './HomeStyles';
import SkeletonCard from 'component/SkeletonCard/SkeletonCard';
import { GApageView } from 'helper/reactga';

const Home = ({ showFilterModal, setShowFilterModal }) => {
  const [filterField, setFilterField] = useState<Object | null>(null);
  const history = useHistory();
  const location = useLocation();

  const { loading, error, data, refetch, networkStatus } = useQuery(VIDEOS_ALL_ACTIVITY_QUERY, { notifyOnNetworkStatusChange: true });

  useEffect(() => {
    refetch();
  }, [location]);

  // if (networkStatus === NetworkStatus.refetch) return <LoadingSpinner />;
  // if (loading) return <LoadingSpinner />;
  // if (data) {
  //   console.log('data', data);
  // }
  // if (error) {
  //   console.log('error', error);
  // }

  const onClickItem = item => {
    history.push({
      pathname: '/video',
      search: `?vid=${item._id}`,
      state: { vid: item },
    });
  };

  const onFilterDone = filters => {
    // console.log('filter done');
    if (
      filters.activity !== 'all' ||
      filters.trainer !== 'all' ||
      filters.date !== null ||
      filters.intensity !== 'all' ||
      filters.duration !== 0 ||
      filters.equipments.length > 0
    ) {
      let ids: Object = {};
      if (filters.activityId) {
        ids = { ...ids, activityId: filters.activityId };
      }
      if (filters.trainerId) {
        ids = { ...ids, trainerId: filters.trainerId };
      }
      if (filters.intensity !== 'all') {
        ids = { ...ids, intensity: filters.intensity };
      }
      if (filters.equipments.length > 0) {
        ids = { ...ids, equipments: filters.equipments };
      }
      if (filters.duration > 0) {
        ids = { ...ids, duration: filters.duration };
      }
      let tempFilterField = {
        date: filters.date == null ? 'all' : filters.date,
        trainer: filters.trainer,
        activity: filters.activity,
        intensity: filters.intensity,
        equipments: filters.equipments.length > 0 ? filters.equipments.join(',') : 'all',
        duration: filters.duration > 0 ? filters.duration : 'all',
      };
      setFilterField(tempFilterField);
      history.push({
        pathname: '/videolist/Filtered%20Videos',
        state: { filterField: { ...tempFilterField }, ...ids },
      });
    } else {
      setFilterField(null);
    }
    setShowFilterModal(false);
  };
  const onFilterCancel = () => {
    // console.log('filter cancelled');
    setShowFilterModal(false);
  };
  return (
    <HomeWrapper>
      {(loading || networkStatus === NetworkStatus.refetch) && (
        <>
          {[1, 2, 3].map(item => (
            <div key={item}>
              <SkeletonCard />
            </div>
          ))}
        </>
      )}
      {!loading &&
        networkStatus !== NetworkStatus.refetch &&
        data &&
        data.videosByAllActivity.map(activity => {
          if (activity.videos && activity.videos.length > 0) {
            return (
              <div key={activity._id}>
                {activity.name == 'Latest Videos' || activity.name == 'Popular Videos' ? (
                  <TitleWrapper>{activity.name}</TitleWrapper>
                ) : (
                  <Link to={{ pathname: `/videolist/${activity.name}`, state: { activityId: activity._id } }}>
                    <TitleWrapper>{activity.name}</TitleWrapper>
                  </Link>
                )}
                <VideoListScroll videos={activity.videos} onClick={onClickItem} />
              </div>
            );
          }
        })}
      <VideoFilterModal onDone={onFilterDone} onCancel={onFilterCancel} visible={showFilterModal} />
    </HomeWrapper>
    // <div></div>
  );
};

export default Home;
