import { Layout as AntdLayout } from 'antd';
import styled from 'styled-components';

export const StyledHeader = styled(AntdLayout.Header)`
  height: 64px;
  color: #111;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  z-index: 999;
  background-color: #fff;
`;

/*===============
    header
================*/

export const HeaderWrapper = styled.div`
  color: #111;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  z-index: 999;
  background-color: #fff;
`;

export const HeaderContainer = styled.div`
  width: 95%;
  max-width: 1820px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: space-between;
  @media (min-width: 675px) {
    text-align: left;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
`;

/*===============
    logo
================*/

export const LogoWrapper = styled.div`
  margin: 1em 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 675px) {
    display: block;
    width: 25%;
  }
`;
export const LogoImg = styled.img`
  max-width: 300px;
  width: 85%;
  margin: 0 auto;
  @media (min-width: 675px) {
    display: block;
    width: 100%;
    margin: 0;
  }
`;

/*===============
    Menu
================*/

export const MenuWrapper = styled.div`
  display: block;
  text-align: right;
  width: 15%;
  @media (min-width: 675px) {
    display: none;
  }
`;

/*===============
    search
================*/

export const SearchWrapper = styled.div`
  margin: 1em auto;
  width: 100%;
  max-width: 500px;
  display: ${(props: any) => (props.show ? 'block' : 'none')};
  @media (min-width: 675px) {
    width: 40%;
    text-align: center;
    display: block;
  }
`;

/*===============
    buttons
================*/

export const ButtonsWrapper = styled.div`
  margin: 1em 0;
  display: ${(props: any) => (props.show ? 'flex' : 'none')};
  text-align: right;
  align-items: center;
  justify-content: flex-end;
  @media (min-width: 675px) {
    display: flex;
    width: 25%;
    align-items: center;
    text-align: right;
  }
`;
