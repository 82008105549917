import React from 'react';
import { ThemeProvider } from 'styled-components';

const theme = {
  colors: {
    brandDarkBlue: '#00205B',
    brandLightBlue: '#4EC3E0',
    brandDarkGrey: '#191919',
    coolGrey: '#cccccc',
  },
  fonts: ['Gotham', 'Roboto'],
  fontSizes: {
    small: '0.75rem',
    medium: '1rem',
    large: '1.25rem',
  },
};

const Theme = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

export default Theme;
