import React, { useContext, useEffect, useState } from 'react';
import { getUserData, setUserData, getToken } from 'helper';
import moment from 'moment';
import AntdButton from 'component/elements/AntdButton';
import { Button, notification, Form, Radio, Checkbox, Input, DatePicker } from 'antd';
import LoadingSpinner from 'component/Loader';
import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_PARQ_NOTE_MUTATION } from 'apollo/ezSchedule/mutations';
import './../../../component/Layout/Header/notificationStyles.css';
import './parqStyles.css';
import { FOLLOW_UP_Q, GENERAL_HEALTH_Q } from './parqFormQuestion';

const Parq = () => {
  const theme = useContext(ThemeContext);
  const [form] = Form.useForm();
  const history = useHistory();
  const [user, setUser] = useState<any>(JSON.parse(getUserData()) || null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<any>(null);
  const [createParqNote] = useMutation(CREATE_PARQ_NOTE_MUTATION);
  const [generalHealth, setGeneralHealth] = useState<any>(
    GENERAL_HEALTH_Q.map(item => ({
      ...item,
      answer: null,
      subQuestion: (item.subQuestion as any).map(subqItem => ({
        ...subqItem,
        answer: null,
      })),
    }))
  );
  const [followUp, setFollowUp] = useState<any>(
    FOLLOW_UP_Q.map(item => ({
      ...item,
      answer: null,
      subQuestion: (item.subQuestion as any).map(subqItem => ({
        ...subqItem,
        answer: null,
      })),
    }))
  );
  useEffect(() => {
    const token = getToken();
    if (!token) {
      setLoading(false);
      setError('No token');
    } else {
      setLoading(false);
    }
  }, []);

  const handleSubmit = () => {
    // console.log(form.getFieldsValue(GENERAL_HEALTH_Q.map(item => item.label)));
    setSubmitting(true);
    form
      .validateFields()
      .then(values => {
        // console.log('health', generalHealth);
        // console.log('follow', followUp);
        // const input = {
        //   generalHealth: generalHealth,
        //   followUp: Object.values(form.getFieldsValue(GENERAL_HEALTH_Q.map(item => item.label))).includes('YES') ? followUp : [],
        //   participationDeclaration: {
        //     name: `${user.firstName} ${user.lastName}`,
        //     date: moment().format('DD MMM YYYY'),
        //   },
        // };
        let i = `General Health\n`;
        for (const item of generalHealth) {
          i = i + item.question + ':\n' + item.answer + '\n';
          if (item.answer == 'YES' && item.subQuestion.length > 0) {
            for (const sitem of item.subQuestion) {
              i = i + sitem.question + ':\n' + sitem.answer + '\n';
            }
          }
        }
        if (Object.values(form.getFieldsValue(GENERAL_HEALTH_Q.map(item => item.label))).includes('YES')) {
          i = i + 'Follow Up\n';
          for (const item of followUp) {
            i = i + item.question + ':\n' + item.answer + '\n';
            if (item.answer == 'YES' && item.subQuestion.length > 0) {
              for (const sitem of item.subQuestion) {
                i = i + sitem.question + ':\n' + sitem.answer + '\n';
              }
            }
          }
        }
        i = i + 'Participation Declaration\n';
        i = i + `name: ${user.firstName} ${user.lastName}\n`;
        i = i + `date:  ${moment().format('DD MMM YYYY')}\n`;
        // console.log('final', i);
        createParqNote({
          variables: {
            memberId: user && user.virtuagymMemberId ? +user.virtuagymMemberId : null,
            input: i,
          },
        })
          .then(res => {
            setSubmitting(false);
            if (res.data.createParqNote) {
              notification.success({
                message: 'Success',
                duration: 0,
                description: 'Your request is success.',
                className: 'notification',
              });
              history.push('/virtualsession');
            } else {
              notification.error({
                message: 'Fail',
                duration: 0,
                description: 'You request did not succeed',
                className: 'notification',
              });
            }
          })
          .catch(err => {
            setSubmitting(false);
            notification.error({
              message: 'Fail',
              duration: 0,
              description: 'You request did not succeed',
              className: 'notification',
            });
          });
      })
      .catch(err => {
        console.error(err);
        setSubmitting(false);

        notification.error({
          message: 'Fail',
          duration: 0,
          description: 'You request did not succeed',
          className: 'notification',
        });
      });
  };

  const getAnswerbox = (list, setList, item, index, subItem: any = null, subIndex: any = null) => {
    let answerbox: any = null;
    let currentType = subItem ? subItem.type : item.type;
    let currentItem = subItem || item;
    let currentIndex = subIndex || index;
    switch (currentType) {
      case 'radio':
        answerbox = (
          <Radio.Group
            onChange={e => {
              let temp = [...list];
              if (subItem && subIndex != null) {
                temp[index].subQuestion[subIndex].answer = e.target.value;
              } else {
                temp[currentIndex].answer = e.target.value;
                if (e.target.value == 'NO') {
                  const f = {};
                  const subTemp = temp[currentIndex].subQuestion.map(subItem => {
                    f[subItem.label] = null;
                    return { ...subItem, answer: null };
                  });
                  form.setFieldsValue(f);
                  temp[currentIndex].subQuestion = subTemp;
                }
              }
              setList(temp);
            }}
          >
            {currentItem.possibleAns.map(a => (
              <Radio key={a} value={a}>
                {a}
              </Radio>
            ))}
          </Radio.Group>
        );
        break;
      case 'checkbox':
        answerbox = (
          <Checkbox.Group
            onChange={val => {
              let temp = [...list];
              if (subItem && subIndex != null) {
                temp[index].subQuestion[subIndex].answer = val;
              } else {
                temp[currentIndex].answer = val;
              }
              setList(temp);
            }}
          >
            {currentItem.possibleAns.map(a => (
              <Checkbox key={a} style={{ lineHeight: '32px' }} value={a}>
                {a}
              </Checkbox>
            ))}
          </Checkbox.Group>
        );
        break;
      case 'input':
        answerbox = (
          <Input
            onChange={e => {
              let temp = [...list];
              if (subItem && subIndex != null) {
                temp[index].subQuestion[subIndex].answer = e.target.value;
              } else {
                temp[currentIndex].answer = e.target.value;
              }
              setList(temp);
            }}
          />
        );
        break;
    }
    return answerbox;
  };
  if (loading) return <LoadingSpinner />;
  return (
    <div style={{ padding: 0 }}>
      <div style={{ paddingTop: 10 }}>
        <Button type="text" onClick={() => history.goBack()}>
          <LeftOutlined style={{ color: '#000', fontSize: 18 }} />
        </Button>
      </div>
      {error ? (
        <span style={{ padding: 15 }}>{error}</span>
      ) : (
        <div style={{ padding: '5px 15px' }}>
          <div style={{ marginBottom: 15, textAlign: 'center' }}>
            <h3 style={{ margin: 0 }}>PAR-Q</h3>
            <span>The Physical Activity Readiness Questionnaire for Everyone</span>
          </div>
          <div>
            <Form form={form}>
              <div style={{ padding: 10, border: '1px solid #e1e1e1', borderRadius: 10 }}>
                <h3 style={{ textAlign: 'center' }}>General Health Questions</h3>
                {GENERAL_HEALTH_Q.map((item, index) => {
                  let answerbox = getAnswerbox(generalHealth, setGeneralHealth, item, index);
                  return (
                    <div key={item.label}>
                      <Form.Item label={item.question} name={item.label} rules={[{ required: true, message: 'Please select one of the options' }]}>
                        {answerbox}
                      </Form.Item>
                      {generalHealth[index].answer == 'YES' &&
                        item.subQuestion &&
                        item.subQuestion.length > 0 &&
                        (item.subQuestion as any).map((subItem: any, subIndex) => {
                          let subAnswerbox = getAnswerbox(generalHealth, setGeneralHealth, item, index, subItem, subIndex);
                          return (
                            <Form.Item
                              key={subItem.label}
                              label={subItem.question}
                              name={subItem.label}
                              rules={[{ required: true, message: 'Please select one of the options' }]}
                            >
                              {subAnswerbox}
                            </Form.Item>
                          );
                        })}
                    </div>
                  );
                })}
              </div>
              {Object.values(form.getFieldsValue(GENERAL_HEALTH_Q.map(item => item.label))).includes('YES') && (
                <div style={{ marginTop: 15, padding: 10, border: '1px solid #e1e1e1', borderRadius: 10 }}>
                  <h3 style={{ textAlign: 'center' }}>Follow-up Questions about medical condition(s)</h3>
                  {FOLLOW_UP_Q.map((item, index) => {
                    let answerbox = getAnswerbox(followUp, setFollowUp, item, index);
                    return (
                      <div key={item.label}>
                        <Form.Item label={item.question} name={item.label} rules={[{ required: true, message: 'Please select one of the options' }]}>
                          {answerbox}
                        </Form.Item>
                        {followUp[index].answer == 'YES' &&
                          item.subQuestion &&
                          item.subQuestion.length > 0 &&
                          (item.subQuestion as any).map((subItem: any, subIndex) => {
                            let subAnswerbox = getAnswerbox(followUp, setFollowUp, item, index, subItem, subIndex);
                            return (
                              <Form.Item
                                key={subItem.label}
                                label={subItem.question}
                                name={subItem.label}
                                rules={[{ required: true, message: 'Please select one of the options' }]}
                              >
                                {subAnswerbox}
                              </Form.Item>
                            );
                          })}
                      </div>
                    );
                  })}
                </div>
              )}
              {/*           <div style={{ marginTop: 15, padding: 10, border: '1px solid #e1e1e1', borderRadius: 10 }}>
                <h3 style={{ textAlign: 'center' }}>Participant Declaration</h3>
                <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please enter your name' }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="date" label="Date" rules={[{ required: true, message: 'Please enter date' }]}>
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item name="signature" label="Signature" rules={[{ required: true, message: 'Please enter your name' }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="witness" label="Witness">
                  <Input />
                </Form.Item>
                <Form.Item name="signatureGuardian" label="Signature of Parent/Guardian/Care Provider">
                  <Input />
                </Form.Item>
                </div>*/}
              <Form.Item wrapperCol={{ span: 24 }} style={{ marginTop: 15 }}>
                <AntdButton loading={submitting} type="primary" style={{ width: '100%' }} onClick={handleSubmit}>
                  Submit
                </AntdButton>
              </Form.Item>
            </Form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Parq;
