import React, { useContext } from 'react';
import { Button } from 'antd';
import { ThemeContext } from 'styled-components';
import moment from 'moment';
const ScheduleDateSelect = ({ selectedDate, handleChangeDay, disabled }) => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <div style={{ display: 'flex', marginBottom: 10, background: theme.colors.brandDarkBlue }}>
        <div
          style={{
            textAlign: 'center',
            flexBasis: `${100 / 3}%`,
          }}
        >
          <Button disabled={disabled} size="large" type="text" style={{ color: 'white' }} onClick={() => handleChangeDay('today')}>
            <span
              style={{
                fontSize: '1rem',
                ...(moment().format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD') && {
                  textDecorationLine: 'underline',
                  textDecorationColor: theme.colors.brandLightBlue,
                }),
              }}
            >
              Today
            </span>
          </Button>
        </div>
        <div
          style={{
            textAlign: 'center',
            flexBasis: `${100 / 3}%`,
            borderLeft: `1px solid  ${theme.colors.coolGrey}`,
            borderRight: `1px solid ${theme.colors.coolGrey}`,
          }}
        >
          <Button disabled={disabled} size="large" type="text" style={{ color: 'white' }} onClick={() => handleChangeDay('tomorrow')}>
            <span
              style={{
                fontSize: '1rem',
                ...(moment().add(1, 'day').format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD') && {
                  textDecorationLine: 'underline',
                  textDecorationColor: theme.colors.brandLightBlue,
                }),
              }}
            >
              Tomorrow
            </span>
          </Button>
        </div>
        <div
          style={{
            textAlign: 'center',
            flexBasis: `${100 / 3}%`,
          }}
        >
          <Button disabled={disabled} size="large" type="text" style={{ color: 'white' }} onClick={() => handleChangeDay('dayAfter')}>
            <span
              style={{
                fontSize: '1rem',
                ...(moment().add(2, 'days').format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD') && {
                  textDecorationLine: 'underline',
                  textDecorationColor: theme.colors.brandLightBlue,
                }),
              }}
            >
              Day After
            </span>
          </Button>
        </div>
      </div>
      <div style={{ textAlign: 'center' }}>
        <h2>{moment(selectedDate).format('ddd, MMMM DD, YYYY')}</h2>
      </div>
    </>
  );
};

export default ScheduleDateSelect;
