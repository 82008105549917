import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, notification } from "antd";
import Loader from "react-loader-spinner";
import moment from "moment";
import {
  CalendarOutlined,
  ClockCircleOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { useMutation } from "@apollo/react-hooks";
import { ThemeContext } from "styled-components";
import {
  BOOK_EZ_SESSION_MUTATION,
  WAITLIST_EZ_SESSION_MUTATION,
} from "apollo/ezSchedule/mutations";
import { getUserData } from "helper";
import "./../Layout/Header/notificationStyles.css";

const SessionModal = ({ visible, selectedSession, onDone, onCancel }) => {
  const theme = useContext(ThemeContext);
  const [bookSession] = useMutation(BOOK_EZ_SESSION_MUTATION);
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(getUserData());
  const [waitlistSession] = useMutation(WAITLIST_EZ_SESSION_MUTATION);
  useEffect(() => {
    if (selectedSession) {
      console.log("selected", selectedSession);
    }
    return () => {};
  }, [selectedSession]);

  const handleBook = () => {
    setLoading(true);
    bookSession({
      variables: {
        sessionId: selectedSession.id,
        memberId:
          user && user.virtuagymMemberId ? +user.virtuagymMemberId : null,
        ezAudienceId: selectedSession.reservationSubCategoryId,
        bookingTime: selectedSession.startTimeUTC,
      },
    })
      .then(({ data }) => {
        setLoading(false);
        if (data.bookEzSession) {
          notification.success({
            message: "Book Session Success",
            duration: 0,
            description: 'This reservation will now appear in My Activities. Hit "Sync" under Settings if you do not see it',
            className: 'notification',
          });
          onDone(selectedSession.id, "Scheduled");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err book", err);
        notification.error({
          message: "Book Session Failed",
          duration: 0,
          description: err.toString().replace("Error: GraphQL error:", ""),
          className: "notification",
        });
      });
  };

  const handleWaitlist = () => {
    setLoading(true);
    waitlistSession({
      variables: {
        sessionId: selectedSession.id,
      },
    })
      .then(({ data }) => {
        setLoading(false);
        if (data.waitlistEzSession) {
          notification.success({
            message: "Waitlist Session Success",
            duration: 0,
            description: 'This reservation will now appear in My Activities. Hit "Sync" under Settings if you do not see it',
            className: 'notification',
          });
          onDone(selectedSession.id, "Waitlisted");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err waitlist", err);
        notification.error({
          message: "Waitlist Session Failed",
          duration: 0,
          description: err.toString().replace("Error: GraphQL error:", ""),
          className: "notification",
        });
      });
  };

  const footer = [
    ...(selectedSession &&
    selectedSession.bookable &&
    selectedSession.allowWaitlisting &&
    selectedSession.slotsAvailable == 0
      ? [
          <Button
            style={{ background: theme.colors.brandDarkBlue }}
            loading={loading}
            key="submit"
            type="primary"
            onClick={handleWaitlist}
          >
            WAITLIST
          </Button>,
        ]
      : []),
    ...(selectedSession &&
    selectedSession.bookable &&
    selectedSession.slotsAvailable != 0
      ? [
          <Button
            style={{ background: theme.colors.brandDarkBlue }}
            loading={loading}
            key="submit"
            type="primary"
            onClick={handleBook}
          >
            BOOK
          </Button>,
        ]
      : []),
  ];
  return (
    <Modal
      title={
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: 8 }}>
            <CalendarOutlined />
          </div>
          <div>{selectedSession && selectedSession.title}</div>
        </div>
      }
      visible={visible}
      onOk={onDone}
      onCancel={onCancel}
      footer={footer}
    >
      {selectedSession ? (
        <div>
          <div>{selectedSession.description}</div>
          <div style={{ display: "flex", marginTop: 10 }}>
            <div style={{ marginRight: 5 }}>
              <ClockCircleOutlined />
            </div>
            <div>
              <div>
                <span>
                  {`${moment(selectedSession.startTimeUTC).format(
                    "ddd, DD MMM, YYYY h:mm a"
                  )} - ${moment(selectedSession.endTimeUTC).format(
                    "ddd, DD MMM, YYYY h:mm a"
                  )}`}
                </span>
              </div>
              <div>
                <span style={{ color: theme.colors.coolGrey }}>{`${
                  ((moment(selectedSession.endTimeUTC) as any) -
                    (moment(selectedSession.startTimeUTC) as any)) /
                  (1000 * 60 * 60)
                } hour(s)`}</span>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", marginTop: 10 }}>
            <div style={{ marginRight: 5 }}>
              <TeamOutlined />
            </div>
            <div>
              <div>
                <span>{`${selectedSession.slotsAvailable} Spot(s) Left`}</span>
              </div>
              <div>
                <span
                  style={{ color: theme.colors.coolGrey }}
                >{`Class size of ${selectedSession.slotsTotal}`}</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Loader type="TailSpin" color="#202541" />
        </div>
      )}
    </Modal>
  );
};

export default SessionModal;
