import { gql } from "apollo-boost";

export const BOOK_EZ_SESSION_MUTATION = gql`
  mutation bookEzSession(
    $sessionId: Int!
    $memberId: Int
    $ezAudienceId: Int
    $bookingTime: String
  ) {
    bookEzSession(
      sessionId: $sessionId
      memberId: $memberId
      ezAudienceId: $ezAudienceId
      bookingTime: $bookingTime
    )
  }
`;
