import React, { useEffect } from 'react';
import Router from './Router';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyles from './globalStyles';
import Theme from 'theme';

const App: React.FC = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Theme>
          <GlobalStyles />
          <Router />
        </Theme>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
