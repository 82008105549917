import { gql } from 'apollo-boost';

export const EZ_CLIENT_SIGNATURE_LOGGN = gql`
  mutation ezClientSignatureLogin($clientId: String!, $memberId: String!) {
    ezClientSignatureLogin(clientId: $clientId, memberId: $memberId) {
      username
      firstName
      lastName
      jwt
      ClientID
      MembershipType
      MemberPhotoUrl
    }
  }
`;
