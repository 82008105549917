import React, { useContext, useEffect, useState } from 'react';
import { getUserData, setUserData, getToken } from 'helper';
import moment from 'moment';
import AntdButton from 'component/elements/AntdButton';
import { Button, notification, Form, Radio, Checkbox, Input } from 'antd';
import LoadingSpinner from 'component/Loader';
import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_BIWEEKLY_CHECKIN_MUTATION } from 'apollo/ezSchedule/mutations';
import './../../../component/Layout/Header/notificationStyles.css';

const PurchaseMoreTraining = () => {
  const theme = useContext(ThemeContext);
  const [form] = Form.useForm();
  const history = useHistory();
  const [user, setUser] = useState<any>(JSON.parse(getUserData()) || null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  const [createBiweeklyCheckin] = useMutation(CREATE_BIWEEKLY_CHECKIN_MUTATION);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const token = getToken();
    if (!token) {
      setLoading(false);
      setError('No token');
    } else {
      setLoading(false);
    }
  }, []);

  const handleSubmit = () => {
    setSubmitting(true);

    form
      .validateFields()
      .then(values => {
        // console.log('values', values);
        let input = ``;
        for (const key in values) {
          input = input + key + ':' + values[key] + '\n';
        }
        // console.log('input', input);
        // setSubmitting(false);
        createBiweeklyCheckin({
          variables: {
            memberId: user && user.virtuagymMemberId ? +user.virtuagymMemberId : null,
            input: input,
          },
        })
          .then(res => {
            setSubmitting(false);
            if (res.data.createBiweeklyCheckin) {
              notification.success({
                message: 'Success',
                duration: 0,
                description: 'Your request is success.',
                className: 'notification',
              });
              history.push('/virtualsession');
            } else {
              notification.error({
                message: 'Fail',
                duration: 0,
                description: 'You request did not succeed',
                className: 'notification',
              });
            }
          })
          .catch(err => {
            setSubmitting(false);
            notification.error({
              message: 'Fail',
              duration: 0,
              description: 'You request did not succeed',
              className: 'notification',
            });
          });
      })
      .catch(err => {
        console.error(err);
        setSubmitting(false);
        notification.error({
          message: 'Fail',
          duration: 0,
          description: 'You request did not succeed',
          className: 'notification',
        });
      });
  };

  if (loading) return <LoadingSpinner />;
  return (
    <div style={{ padding: 0 }}>
      <div style={{ paddingTop: 10 }}>
        <Button type="text" onClick={() => history.goBack()}>
          <LeftOutlined style={{ color: '#000', fontSize: 18 }} />
        </Button>
      </div>
      {error ? (
        <span style={{ padding: 15 }}>{error}</span>
      ) : (
        <div style={{ padding: '5px 15px' }}>
          <div style={{ marginBottom: 15, textAlign: 'center' }}>
            <h3 style={{ margin: 0 }}>Check-in</h3>
          </div>
          <div>
            <Form form={form}>
              <Form.Item
                label="How was your sleep quality over the last reporting period?"
                name="sleepQuality"
                rules={[{ required: true, message: 'Please select one of the options' }]}
              >
                <Radio.Group>
                  <Radio.Button value="1">1</Radio.Button>
                  <Radio.Button value="2">2</Radio.Button>
                  <Radio.Button value="3">3</Radio.Button>
                  <Radio.Button value="4">4</Radio.Button>
                  <Radio.Button value="5">5</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: 'Please select one of the options' }]}
                label="How has your soreness been over the last reporting period? (1 extremely sore - 5 not sore at all)"
                name="soreness"
                required={true}
              >
                <Radio.Group>
                  <Radio.Button value="1">1</Radio.Button>
                  <Radio.Button value="2">2</Radio.Button>
                  <Radio.Button value="3">3</Radio.Button>
                  <Radio.Button value="4">4</Radio.Button>
                  <Radio.Button value="5">5</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: 'Please select one of the options' }]}
                label="How has your mood been?"
                name="mood"
                required={true}
              >
                <Radio.Group>
                  <Radio.Button value="1">1</Radio.Button>
                  <Radio.Button value="2">2</Radio.Button>
                  <Radio.Button value="3">3</Radio.Button>
                  <Radio.Button value="4">4</Radio.Button>
                  <Radio.Button value="5">5</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: 'Please select one of the options' }]}
                label="How have your energy levels been?"
                name="energyLevel"
                required={true}
              >
                <Radio.Group>
                  <Radio.Button value="1">1</Radio.Button>
                  <Radio.Button value="2">2</Radio.Button>
                  <Radio.Button value="3">3</Radio.Button>
                  <Radio.Button value="4">4</Radio.Button>
                  <Radio.Button value="5">5</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: 'Please select one of the options' }]}
                label="How high has your motivation been to train?"
                name="motivation"
                required={true}
              >
                <Radio.Group>
                  <Radio.Button value="1">1</Radio.Button>
                  <Radio.Button value="2">2</Radio.Button>
                  <Radio.Button value="3">3</Radio.Button>
                  <Radio.Button value="4">4</Radio.Button>
                  <Radio.Button value="5">5</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="Pain Areas" name="painAreas" initialValue={[]}>
                <Checkbox.Group>
                  <Checkbox style={{ lineHeight: '32px', marginLeft: 8 }} value="head">
                    Head
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="neck">
                    Neck
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="rightShoulder">
                    Right Shoulder
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="leftShoulder">
                    Left Shoulder
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="rightBicep">
                    Right Bicep
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="leftBicep">
                    Left Bicep
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="rightTricep">
                    Right Tricep
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="leftTricep">
                    Left Tricep
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="rightForearm">
                    Right Forearm
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="leftForearm">
                    Left Forearm
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="rightHandFingers">
                    Right Hand/Fingers
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="leftHandFingers">
                    Left Hand/Fingers
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="chest">
                    Chest
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="abdomen">
                    Abdomen
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="lowerBack">
                    Lower Back
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="pelvis">
                    Pelvis
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="buttock">
                    Buttock
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="rightQuads">
                    Right Quads
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="leftQuads">
                    Left Quads
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="rightHamstring">
                    Right Hamstring
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="leftHamstring">
                    Left Hamstring
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="rightShin">
                    Right Shin
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="leftShin">
                    Left Shin
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="rightCalve">
                    Right Calve
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="leftCalve">
                    Left Calve
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="rightAnkle">
                    Right Ankle
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="leftAnkle">
                    Left Ankle
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="rightFootToes">
                    Right Foot/Toes
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="leftFootToes">
                    Left Foot/Toes
                  </Checkbox>
                </Checkbox.Group>
              </Form.Item>
              <Form.Item label="Sore Areas" name="soreAreas" initialValue={[]}>
                <Checkbox.Group>
                  <Checkbox style={{ lineHeight: '32px', marginLeft: 8 }} value="head">
                    Head
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="neck">
                    Neck
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="rightShoulder">
                    Right Shoulder
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="leftShoulder">
                    Left Shoulder
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="rightBicep">
                    Right Bicep
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="leftBicep">
                    Left Bicep
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="rightTricep">
                    Right Tricep
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="leftTricep">
                    Left Tricep
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="rightForearm">
                    Right Forearm
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="leftForearm">
                    Left Forearm
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="rightHandFingers">
                    Right Hand/Fingers
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="leftHandFingers">
                    Left Hand/Fingers
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="chest">
                    Chest
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="abdomen">
                    Abdomen
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="lowerBack">
                    Lower Back
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="pelvis">
                    Pelvis
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="buttock">
                    Buttock
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="rightQuads">
                    Right Quads
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="leftQuads">
                    Left Quads
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="rightHamstring">
                    Right Hamstring
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="leftHamstring">
                    Left Hamstring
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="rightShin">
                    Right Shin
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="leftShin">
                    Left Shin
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="rightCalve">
                    Right Calve
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="leftCalve">
                    Left Calve
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="rightAnkle">
                    Right Ankle
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="leftAnkle">
                    Left Ankle
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="rightFootToes">
                    Right Foot/Toes
                  </Checkbox>
                  <Checkbox style={{ lineHeight: '32px' }} value="leftFootToes">
                    Left Foot/Toes
                  </Checkbox>
                </Checkbox.Group>
              </Form.Item>
              <Form.Item label="Is there anything else you would like to share with your trainer?" initialValue="" name="shareWithTrainer">
                <Input />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 24 }}>
                <AntdButton loading={submitting} type="primary" style={{ width: '100%' }} onClick={handleSubmit}>
                  Submit
                </AntdButton>
              </Form.Item>
            </Form>
          </div>
        </div>
      )}
    </div>
  );
};

export default PurchaseMoreTraining;
