import styled from 'styled-components';
import { Table } from 'antd';

const AntdTable = styled(Table)<any>`
  table {
    font-size: 1rem;
    width: 100%;
    border: 1px solid #d0d0d0;
    min-width: auto;
  }
  tr > th,
  tr > td {
    padding: 0.75em;
  }
`;

export default AntdTable;
