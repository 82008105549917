import styled from 'styled-components';

export const HomeWrapper = styled.div`
  width: 95%;
  max-width: 1820px;
  margin: 0 auto;
`;

export const TitleWrapper = styled.h1`
  margin-bottom: 0;
  padding-left: 10px;
  padding-top: 1em;
  padding-bottom: 0.125em;
  font-size: 2rem;
`;
