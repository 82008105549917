import React from 'react';
import { Card, Row, Avatar, Col } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import { VideoTitle, InfoWrapper } from './VideoItemStyles';
import viewFormatter from 'helper/viewsFormatter';

const VideoItem = ({ onClick, item }) => {
  let views = '0';
  if (item.totalViews) {
    views = viewFormatter(item.totalViews, 1);
  }
  return (
    <Card
      onClick={() => (item.premiereAt && item.vid == null ? {} : onClick(item))}
      style={{
        cursor: item.premiereAt && item.vid == null ? 'default' : 'pointer',
        background: 'transparent',
      }}
      bodyStyle={{ padding: '0em' }}
    >
      <div style={{ position: 'relative', paddingBottom: '56.2%' }}>
        <img
          alt="video preview"
          src={item.thumbnail || require('assets/panamthumbnail.png')}
          onError={(e: any) => {
            e.target.onerror = null;
            e.target.src = require('assets/panamthumbnail.png');
          }}
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            objectFit: 'cover',
          }}
          draggable={false}
        />
      </div>
      <InfoWrapper>
        <div>
          <Avatar size={45} icon={<UserOutlined />} src={item.trainer && item.trainer.trainerPicUrl} />
        </div>
        <div style={{ marginLeft: '0.7em' }}>
          <VideoTitle style={{ margin: 0, padding: 0 }}>
            {item.title ||
              `${item.activity.name} - ${
                item.premiereAt ? moment(item.premiereAt).format('MMM D, YYYY') : moment(item.createdAt).format('MMM D, YYYY')
              }`}
          </VideoTitle>
          <p style={{ margin: 0, padding: 0, fontSize: '1.25rem', fontWeight: 300, lineHeight: '1.5rem' }}>
            {(item.trainer && item.trainer.name) || 'Trainer Name'} <span>&#183;</span> {(item.activity && item.activity.name) || 'Activity'}
          </p>
          <p style={{ margin: 0, padding: 0, fontSize: '1.25rem', fontWeight: 300, lineHeight: '1.5rem' }}>
            {views} views <span>&#183;</span> {item.premiereAt ? moment(item.premiereAt).fromNow() : moment(item.createdAt).fromNow()}
          </p>
          {item.premiereAt && item.vid == null && (
            <p style={{ margin: 0, padding: 0, fontSize: '1rem', fontWeight: 300 }}>{`Premiere at ${moment(item.premiereAt).format(
              'DD/MM/YYYY hh:mm a'
            )}`}</p>
          )}
          {item.isLive && (
            <span
              style={{
                margin: 0,
                padding: '0.2em',
                fontSize: '1rem',
                fontWeight: 400,
                color: '#FF4E45',
                borderRadius: 2,
                lineHeight: '1rem',
                border: '1px solid #FF4E45',
              }}
            >
              Live Now
            </span>
          )}
          {item.recentPremiere && item.premiereAt && (
            <span
              style={{
                margin: 0,
                padding: '0.2em',
                fontSize: '1rem',
                fontWeight: 400,
                color: '#4EC3E0',
                borderRadius: 2,
                lineHeight: '1rem',
                border: '1px solid #4EC3E0',
              }}
            >{`Premiered at ${moment(item.premiereAt).format('MMM DD,YYYY hh:mm a')}`}</span>
          )}
        </div>
      </InfoWrapper>
    </Card>
  );
};

export default VideoItem;
