import { createGlobalStyle } from 'styled-components';

const GlobalStyles: any = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html{
    font-size:75%;
    @media (min-width: 500px) {
      font-size:100%;
    }
  }

  body {
    margin: 0;
    padding: 0;
    font-family: Gotham,"Open Sans", sans-serif;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  h1,h2,h3,h4,h5,h6{
    font-family: Gotham,'PT Sans', sans-serif;
    font-weight: 700;
    color: ${(props: any) => props.theme.colors.brandDarkBlue};
  }
  & .content-scrollbar .scrollbar-track{
    width: 3px;
  }
  & .content-scrollbar .scrollbar-thumb{
    width: 3px;
  }
`;

export default GlobalStyles;
