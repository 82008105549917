import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import './styles.css';

export default class VideoPlayer extends React.PureComponent {
  componentDidMount() {
    // instantiate Video.js
    // console.log('props', this.props);
    this.player = videojs(this.videoNode, this.props.videoJsOptions, function onPlayerReady() {
      // console.log('onPlayerReady', this);
      //  this.props.setPlayerInstance(this.player);
    });
    this.init_player_events(this.props);
  }
  init_player_events(props) {
    let currentTime = 0;
    let previousTime = 0;
    let position = 0;

    this.player.ready(() => {
      window.player = this.player;
    });
    this.player.on('play', () => {
      props.onPlay(this.player.currentTime());
    });
    // this.player.on('pause', () => {
    //   props.onPause(this.player.currentTime());
    // });
    this.player.on('timeupdate', e => {
      props.onTimeUpdate(this.player.currentTime(), this.player.duration());
      // previousTime = currentTime;
      // currentTime = this.player.currentTime();
      // if (previousTime < currentTime) {
      //   position = previousTime;
      //   previousTime = currentTime;
      // }
    });
    // this.player.on('seeking', () => {
    //   this.player.off('timeupdate', () => {});
    //   this.player.one('seeked', () => {});
    //   props.onSeeking(this.player.currentTime());
    // });

    // this.player.on('seeked', () => {
    //   let completeTime = Math.floor(this.player.currentTime());
    //   props.onSeeked(position, completeTime);
    // });
    this.player.on('ended', () => {
      props.onEnd(this.player.duration());
    });
  }
  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div>
        <div data-vjs-player>
          <video
            id="videoPlayer"
            ref={node => (this.videoNode = node)}
            autoPlay={true}
            preload="auto"
            className="video-js vjs-theme-forest"
            onContextMenu={e => e.preventDefault()}
          ></video>
        </div>
      </div>
    );
  }
}
