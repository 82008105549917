import { gql } from 'apollo-boost';
import { TRAINER_FRAGMENT } from 'apollo/trainer/trainerFragment';

export const VIDEO_FRAGMENT = gql`
  fragment Video on Video {
    _id
    isLive
    url
    thumbnail
    vid
    title
    description
    tags
    activity {
      _id
      name
    }
    trainer {
      ...Trainer
    }
    premium
    recentPremiere
    premiere
    premiereAt
    totalViews
    duration
    intensity
    equipments
    allowComments
    createdAt
    updatedAt
  }
  ${TRAINER_FRAGMENT}
`;
