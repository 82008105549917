import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import AntdButton from '../../../component/elements/AntdButton';
import { setToken, setUserData } from '../../../helper';
import { Form, Card, Input, Button } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { EZ_CLIENT_LOGGN } from 'apollo/user/mutations';
import { Link, useHistory, useLocation } from 'react-router-dom';

const LoginWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
`;

const FormWrapper = styled.div`
  width: 100%;
`;

const FormTitle = styled.div`
  font-size: ${(props: any) => props.theme.fontSizes.large};
  font-weight: 700;
  text-align: center;
  color: ${(props: any) => props.theme.colors.brandDarkGrey};
`;
const InfoText = styled.p`
  font-size: ${(props: any) => props.theme.fontSizes.small};
`;

const ForgotPassword = styled.a`
  font-size: ${(props: any) => props.theme.fontSizes.small};
  color: ${(props: any) => props.theme.colors.brandDarkBlue};
  font-weight: 300;
  text-align: right;
`;

interface Props {}

const LoginForm = (props: Props) => {
  const [form] = Form.useForm();
  const [loginUser] = useMutation(EZ_CLIENT_LOGGN);
  const [redirectUri, setRedirectUri] = useState<any>(null);
  const history = useHistory();
  const location = useLocation();

  const setLoginToken = async (token, redirect) => {
    await setToken(token);
    if (redirect) {
      window.location.assign(redirect);
    } else {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (location) {
      let queryParams = new URLSearchParams(location.search);
      let token = queryParams.get('token');
      let redirect = queryParams.get('redirect');
      console.log('redirect', redirect);
      setRedirectUri(redirect);
      if (token) {
        // console.log('token ', token);
        setLoginToken(token, redirect);
      }
    }
  }, [location]);

  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [err, setErr] = useState<string>();
  const [load, setLoad] = useState<boolean>();

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const handleLoginInput = (val: string, setter: Function) => {
    if (err) {
      setErr('');
    }
    setter(val);
  };

  const handleLogin = () => {
    setLoad(true);
    form
      .validateFields()
      .then(done => {
        loginUser({
          variables: {
            username: username,
            password: password,
          },
        })
          .then(async result => {
            // console.log('done>>>>>>', result);
            if (result.data && result.data.ezClientlogin) {
              const { jwt, ...rest } = result.data.ezClientlogin;
              await setToken(jwt);
              await setUserData(JSON.stringify(rest));
            }
            console.log('redirecturi', redirectUri);
            if (redirectUri) {
              window.location.assign(redirectUri);
            } else {
              window.location.reload();
            }
            setLoad(false);
          })
          .catch(error => {
            if (error.graphQLErrors && error.graphQLErrors.length) {
              const errMessage = error.graphQLErrors[0].message;
              setErr(errMessage);
              setLoad(false);
            }
          });
      })
      .catch(err => {
        console.log('err', err);
        setLoad(false);
      });
  };

  return (
    <LoginWrapper>
      <div style={{ width: "80%", maxWidth: "300px", marginBottom: "4em" }}>
        <img
          src={require("assets/logos/PanAm_logo_RGB.png")}
          style={{ width: "100%" }}
        />
      </div>
      {/* <h1 style={{ textAlign: "center" }}>Self Service Login</h1> */}
      <FormWrapper>
        <Card
          title={<FormTitle>Login</FormTitle>}
          style={{
            maxWidth: "400px",
            minWidth: "320px",
            width: "90%",
            borderRadius: "5px",
            backgroundColor: "#fff",
            margin: "0 auto",
            boxShadow: "5px 5px 10px 0px rgba(0,0,0,0.3)",
          }}
        >
          <InfoText>
            Please login with your TPASC EzFacility Username and password that
            you use to access our self service
          </InfoText>
          <p style={{ color: "#f00", marginBottom: "10px" }}>
            <strong>{err}</strong>
          </p>
          <Form {...layout} form={form} name="login">
            <Form.Item
              label="Username"
              name="username"
              labelAlign="left"
              rules={[{ required: true, message: "Enter your Username" }]}
            >
              <Input
                prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                onChange={(e) => handleLoginInput(e.target.value, setUsername)}
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              labelAlign="left"
              rules={[{ required: true, message: "Enter your password" }]}
            >
              <Input.Password
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                onChange={(e) => handleLoginInput(e.target.value, setPassword)}
              />
            </Form.Item>
            <div style={{ textAlign: "right", marginBottom: "1em" }}>
              <ForgotPassword href="https://tpasc.ezfacility.com/RetrievePassword">
                Forgot your password?
              </ForgotPassword>
              <p style={{ fontSize: "0.5rem" }}>
                Reset it on your self service page under "Login" and click
                "Having Trouble"
              </p>
            </div>
            <Form.Item>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                
                <AntdButton
                  type="primary"
                  onClick={handleLogin}
                  loading={load}
                  style={{ marginLeft: "1em" }}
                >
                  Login
                </AntdButton>
              </div>
            </Form.Item>
          </Form>
        </Card>
      </FormWrapper>
      
    </LoginWrapper>
  );
};

// const Login = Form.create()(LoginForm);

export default LoginForm;
