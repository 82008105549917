import styled from 'styled-components';
import { Button } from 'antd';

const AntdButton = styled(Button)`
  background: ${(props: any) => props.color || props.theme.colors.brandDarkBlue};
  border: 0;
  &:hover,
  &:focus {
    background: ${(props: any) => props.color || props.theme.colors.brandDarkBlue};
  }
`;

export default AntdButton;
