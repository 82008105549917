import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getUserData, setUserData, getToken } from 'helper';
import { useQuery } from '@apollo/react-hooks';
import { EZ_SESSIONS_QUERY } from 'apollo/ezSchedule/queries';
import moment from 'moment';
import AntdTable from 'component/elements';
import { Button } from 'antd';
import LoadingSpinner from 'component/Loader';
import ScheduleDateSelect from 'component/ScheduleDateSelect/ScheduleDateSelect';
import SessionModal from 'component/SessionModal/SessionModal';
import { ThemeContext } from 'styled-components';
import { LeftOutlined } from '@ant-design/icons';

const EzSessions = () => {
  const history = useHistory();
  const theme = useContext(ThemeContext);
  const [ezSessions, setEzSessions] = useState<any>(null);
  const [user, setUser] = useState<any>(JSON.parse(getUserData()) || null);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [selectedSession, setSelectedSession] = useState<any>(null);
  const [showSessionModal, setShowSessionModal] = useState(false);

  const openSessionModal = () => setShowSessionModal(true);
  const closeSessionModal = () => {
    setShowSessionModal(false);
    setSelectedSession(null);
  };
  const onSessionSelect = session => {
    setSelectedSession(session);
    openSessionModal();
  };

  const onSuccess = (sessionId, status = null) => {
    setTableLoading(true);
    let temp = [...ezSessions];
    const index = temp.findIndex(item => item.id == sessionId);
    temp[index] = {
      ...temp[index],
      myBookingStatus: status,
      ...(status == 'Scheduled' && {
        slotsAvailable: temp[index].slotsAvailable - 1,
      }),
    };
    setEzSessions(temp);
    setTableLoading(false);
    closeSessionModal();
  };

  useQuery(EZ_SESSIONS_QUERY, {
    variables: {
      page: 1,
    },
    onCompleted: async data => {
      setLoading(false);
      if (data.ezSessions) {
        setEzSessions(data.ezSessions.ezSessions);
        // console.log(data.ezSessions.ezSessions);

        // if (!user) {
        //   console.log('user set');
        //   await setUserData(JSON.stringify(obj));
        //   setUser(obj);
        // }
      } else {
        setError('Error in getting schedule.');
      }
    },
    onError: err => {
      setLoading(false);
      setError('Error in getting schedule.');
      console.log('error', err);
    },
  });

  useEffect(() => {
    const token = getToken();
    if (!token) {
      setLoading(false);
      setError('No token');
    }
  }, []);

  const columns = [
    {
      key: 'time',
      title: 'Time',
      width: '40%',
      render: record => (
        <p onClick={() => onSessionSelect(record)} style={{ cursor: 'pointer' }}>{`${moment(record.startTimeUTC).format('h:mm a')} - ${moment(
          record.endTimeUTC
        ).format('h:mm a')}`}</p>
      ),
    },
    {
      key: 'actvity',
      title: 'Activity',
      width: '50%',
      render: record => {
        return (
          <p onClick={() => onSessionSelect(record)} style={{ cursor: 'pointer' }}>
            {record.title}
          </p>
        );
      },
    },
    {
      key: 'spots',
      title: 'Spots',
      width: '10%',
      render: record => {
        return <p>{`${record.slotsAvailable}/${record.slotsTotal}`}</p>;
      },
    },
  ];

  if (loading) return <LoadingSpinner />;

  const handleChangeDay = day => {
    switch (day) {
      case 'today':
        setSelectedDate(moment());
        break;
      case 'tomorrow':
        setSelectedDate(moment().add(1, 'day'));
        break;
      case 'dayAfter':
        setSelectedDate(moment().add(2, 'days'));
        break;
      default:
        break;
    }
  };
  return (
    <div style={{ marginBottom: 15 }}>
      <div style={{ background: theme.colors.brandDarkBlue, paddingTop: 10 }}>
        <div style={{ position: 'absolute', width: '100%', textAlign: 'center' }}>
          <h3 style={{ color: '#fff', margin: 0 }}>Sessions</h3>
        </div>
        <Button type="text" onClick={() => history.goBack()}>
          <LeftOutlined style={{ color: '#fff', fontSize: 18 }} />
        </Button>
      </div>
      {error && <span>{error}</span>}
      {ezSessions && (
        <>
          <ScheduleDateSelect disabled={tableLoading} selectedDate={selectedDate} handleChangeDay={handleChangeDay} />
          <div style={{ marginLeft: 5, marginRight: 5 }}>
            <AntdTable
              loading={tableLoading}
              pagination={false}
              rowKey={record => record.id}
              // scroll={{ x: true }}
              columns={columns}
              dataSource={ezSessions.filter(item => moment(item.startTimeUTC).format('YYYY-MM-DD') == moment(selectedDate).format('YYYY-MM-DD'))}
            />
          </div>
        </>
      )}
      <SessionModal visible={showSessionModal} onCancel={closeSessionModal} onDone={onSuccess} selectedSession={selectedSession} />
    </div>
  );
};

export default EzSessions;
