import Cookies from 'js-cookie';

export const getToken = () => Cookies.get('tpasc_vl');

export const setToken = (token) => Cookies.set('tpasc_vl', token, { expires: 1 });

export const removeToken = () => Cookies.remove('tpasc_vl');

export const setUserData = (data) => localStorage.setItem('user', data);

export const getUserData: any = () => localStorage.getItem('user');

export const removeUserData = () => localStorage.removeItem('user');
