import React, { useEffect, useState, useContext } from 'react';
import { HeaderContainer, HeaderWrapper, LogoWrapper, SearchWrapper, ButtonsWrapper, LogoImg, MenuWrapper } from './HeaderStyles';
import { Row, Col, Button, Input, Select, Spin, AutoComplete, Alert, notification, Avatar, Dropdown, Menu, Divider } from 'antd';
import { LogoutOutlined, FilterOutlined, ScheduleOutlined, MenuOutlined, CloseOutlined, UserOutlined } from '@ant-design/icons';
import { removeToken, removeUserData, getUserData } from './../../../helper';
import { Link, useLocation, useHistory, useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { SUGGEST_VIDEOS_QUERY, CURRENTLY_PREMIERING_VIDEO_QUERY } from 'apollo/video/queries';
import debounce from 'lodash/debounce';
import OnlineClassModal from 'component/OnlineClassModal/OnlineClassModal';
import VideoItemNotification from 'component/VideoItemNotification/VideoItemNotification';
import './notificationStyles.css';
import { NEXT_ONLINE_CLASS_QUERY } from 'apollo/class/queries';
import moment from 'moment';
import { ThemeContext } from 'styled-components';
import { GASetUserId, initGA } from 'helper/reactga';
import { FREE_TRIAL_QUERY, USER_FROM_TOKEN_QUERY } from 'apollo/user/queries';
import { setUserData } from '../../../helper';

interface Props {
  toggleFilter: Function;
}

const Header: React.FC<Props> = ({ toggleFilter }) => {
  const location = useLocation();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const [userData, _setUserData] = useState(JSON.parse(getUserData()) || null);
  const [nextOnlineClass, setNextOnlineClass] = useState<any>(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showOnlineClassModal, setShowOnlineClassModal] = useState(false);
  const [searchText, setSearchText] = useState(undefined);
  const [fetching, setFetching] = useState(false);
  const [suggestedVideos, setSuggestedVideos] = useState([]);
  const [showBanner, setShowBanner] = useState(true);
  const [userFromToken] = useLazyQuery(USER_FROM_TOKEN_QUERY, {
    onCompleted: async data => {
      let obj = {
        ClientID: data.userFromToken.clientId,
        firstName: data.userFromToken.firstName,
        lastName: data.userFromToken.lastName,
        MemberPhotoUrl: data.userFromToken.memberPhotoUrl,
      };
      await setUserData(JSON.stringify(obj));
      _setUserData(obj);
    },
  });
  const [searchSuggest] = useLazyQuery(SUGGEST_VIDEOS_QUERY, {
    onCompleted: data => {
      let temp = [];
      temp = data.suggestVideos.map(item => ({
        key: item._id,
        label: <span key={item._id}>{item.title}</span>,
        value: item.title,
      }));
      setSuggestedVideos(temp);
      setFetching(false);
    },
  });

  useEffect(() => {
    if (!JSON.parse(getUserData())) {
      userFromToken();
    }
  }, []);

  useEffect(() => {
    userData && GASetUserId();
  }, [userData]);

  useQuery(NEXT_ONLINE_CLASS_QUERY, {
    onCompleted: data => {
      // console.log('next class', data);
      if (data.nextOnlineClass && data.nextOnlineClass.title) {
        setNextOnlineClass(data.nextOnlineClass);
      }
    },
    onError: err => {
      console.log('err online class', err);
    },
  });
  useQuery(FREE_TRIAL_QUERY, {
    onCompleted: data => {
      // console.log('free trial', data);
      if (data.freeTrial) {
        notification.error({
          message: 'Free Trial Expiring Soon',
          duration: 0,
          description: 'You are on a free trial. It expires on September 15th, 2020. After you will need to add a video package to your account',
          placement: 'bottomLeft',
          className: 'notification',
        });
      }
    },
    onError: err => {
      console.log('err free trial query', err);
    },
  });
  useQuery(CURRENTLY_PREMIERING_VIDEO_QUERY, {
    onCompleted: data => {
      if (data.currentlyPremeiringVideo.length > 0) {
        data.currentlyPremeiringVideo.map(item => {
          notification.open({
            message: 'Premiering Now',
            duration: 0,
            description: <VideoItemNotification item={item} />,
            key: item.title,
            onClick: () => {
              notification.close(item.title);
              history.push({
                pathname: '/video',
                search: `?vid=${item._id}`,
                state: { vid: item },
              });
            },
            className: 'notification',
          });
        });
      }
    },
  });

  const logOut = () => {
    removeToken();
    removeUserData();
    window.location.reload();
  };

  const toggleMenu = () => {
    setShowMenu(prev => !prev);
  };

  const onSearch = value => {
    if (value != '') {
      history.push({
        pathname: '/search',
        search: `?search_query=${value}`,
      });
    }
  };

  const fetchUser = value => {
    setFetching(true);
    searchSuggest({
      variables: {
        search: value,
      },
    });
  };
  const [fetchDebounce] = useState(() => debounce(fetchUser, 1000));

  const findSuggestion = val => {
    setSearchText(val);
    if (!val) {
      setSuggestedVideos([]);
    } else {
      if (val) {
        fetchDebounce(val);
      }
    }
  };

  const dropdownMenu = (
    <div style={{ background: 'white', padding: '1em 0', boxShadow: '0px 5px 10px 5px rgba(0,0,0,0.3)' }}>
      <div style={{ padding: '0 1em', display: 'flex' }}>
        <div>
          <Avatar
            size={50}
            icon={<UserOutlined />}
            src={JSON.parse(getUserData()) && JSON.parse(getUserData()).MemberPhotoUrl}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
          <span style={{ fontSize: '1.15rem' }}>
            {userData ? userData.firstName[0].toUpperCase() + userData.firstName.slice(1) : 'first'}{' '}
            {userData ? userData.lastName[0].toUpperCase() + userData.lastName.slice(1) : 'last'}
          </span>
          <span style={{ fontSize: '1.15rem' }}>{userData && !!userData.username && userData.username}</span>
        </div>
      </div>
      <Divider style={{ margin: '0.5em 0' }} />
      <div style={{ padding: '0 1.5em' }}>
        <Button type="text" onClick={logOut} style={{ padding: 0, verticalAlign: 'middle' }}>
          <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
            <LogoutOutlined style={{ fontSize: '1.5rem', color: '#444' }} />
            <span style={{ margin: 0, marginLeft: 10, fontSize: '1.15rem' }}>Logout</span>
          </div>
        </Button>
      </div>
    </div>
  );

  return (
    <>
      {showBanner && nextOnlineClass && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
            background: themeContext.colors.brandDarkBlue,
            padding: '0.5em 1em',
          }}
        >
          <div style={{ textAlign: 'center', width: '95%', order: 2 }}>
            {/* <span style={{ color: 'white', textAlign: 'center', marginRight: '5%' }}>{`Next Class: Hatha flow - ${moment().format(
              'DD MMM, YYYY'
            )} - ${moment().format('hh:mm a')}`}</span> */}
            <span style={{ color: 'white', marginRight: '5%', fontSize: '1rem' }}>
              {`Next Class: ${nextOnlineClass.title} - ${nextOnlineClass.instructor !== null ? `${nextOnlineClass.instructor} -` : ''} ${moment(
                nextOnlineClass.startTime
              ).format('DD MMM, YYYY')} - ${moment(nextOnlineClass.startTime).format('hh:mm a')}`}
            </span>
          </div>
          <div style={{ textAlign: 'left', width: '5%', order: 1 }}>
            <CloseOutlined style={{ color: 'white' }} onClick={() => setShowBanner(false)} />
          </div>
        </div>
      )}
      <HeaderWrapper>
        <HeaderContainer>
          <LogoWrapper>
            <Link to="/">
              <LogoImg src={require('assets/logos/PanAm_logo_RGB.png')} />
            </Link>
            <MenuWrapper>
              <Button type="link" onClick={toggleMenu}>
                <MenuOutlined style={{ fontSize: '2em', color: '#444' }} />
              </Button>
            </MenuWrapper>
          </LogoWrapper>
          <SearchWrapper {...{ show: showMenu }}>
            <AutoComplete options={suggestedVideos} onSelect={onSearch} style={{ width: '100%' }} onSearch={findSuggestion}>
              <Input.Search size="large" placeholder="Search ..." style={{ width: '100%' }} onSearch={onSearch} enterButton />
            </AutoComplete>
          </SearchWrapper>
          <ButtonsWrapper {...{ show: showMenu }}>
            {((location && location.pathname == '/') || (location && location.pathname == '/videolist/Filtered Videos')) && (
              <span>
                <Button type="link" onClick={() => toggleFilter(true)}>
                  <FilterOutlined style={{ fontSize: '24px', color: '#444' }} />
                </Button>
              </span>
            )}
            <span>
              <Button type="link" onClick={() => setShowOnlineClassModal(true)}>
                <ScheduleOutlined style={{ fontSize: '24px', color: '#444' }} />
              </Button>
            </span>

            <span>
              <Dropdown overlay={dropdownMenu} placement="bottomRight" trigger={['click']}>
                <Avatar size={40} icon={<UserOutlined />} src={userData && userData.MemberPhotoUrl} style={{ cursor: 'pointer' }} />
              </Dropdown>
            </span>
          </ButtonsWrapper>
        </HeaderContainer>
        <OnlineClassModal visible={showOnlineClassModal} onCancel={() => setShowOnlineClassModal(false)} />
      </HeaderWrapper>
    </>
  );
};

export default Header;
